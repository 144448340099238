import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import MainTopicText from "modules/MainTopicText";
import PaginationTab from "modules/PaginationTab";
import NewsCard from "modules/NewsCard";
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import CarouselMain from "modules/CarouselMain";
import { PaginationCustom, NextBox, SkipBox, NumberBox, Box } from "./styled";
import nextButton from "modules/images/nextButton.svg";
import lastButton from "modules/images/lastButton.svg";
import { Pagination } from "react-bootstrap";
const ActivityPage = () => {
  const { t, i18n } = useTranslation();
  const [activity, setactivity] = useState([]);
  const [activityAll, setactivityAll] = useState([]);
  const [banner, setbanner] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);

  const [start, setstart] = useState(0);
  const [stop, setstop] = useState(23);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + `/Relationsnews?start=${start}&stop=${stop}`, CONFIG)
      .then(function (response) {
        setactivity(response.data[1]);
        setactivityAll(response.data[0])
      })
      .catch(function (error) {
      })

      axios
      .get(API_IP + "/banner", CONFIG)
      .then(function (response) {
        setbanner(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);
  // console.log('activity',activity)
  var perPage = 24
  var arrayPage = []
  var page = activityAll.length/perPage
  for(var i = 0 ; i < page ; i++){
    var item = {
      id:i+1,
      name:i+1
    }
    arrayPage.push(item)
  }
  
  const pagenationClick = (item) => {
    var index = item.id
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(index)
    stop_temp = (index*perPage)-1
    start_temp = (stop_temp+1)-perPage
    console.log('pagenationClick',index,start_temp, stop_temp)
    getActivityList(start_temp,stop_temp)
   
  };

  const onNext = () => {
    var page = currentPage + 1
    setcurrentPage(currentPage + 1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getActivityList(start_temp,stop_temp)
  }
  
  const onBack = () => {
    var page = currentPage - 1
    setcurrentPage(currentPage - 1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getActivityList(start_temp,stop_temp)
  }

  const onFirstPage = () => {
    var page = 1
    setcurrentPage(1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getActivityList(start_temp,stop_temp)
  }

  const onLastPage = () => {
    var page = arrayPage[arrayPage.length-1].id
    setcurrentPage(1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getActivityList(start_temp,stop_temp)
  }

  const getActivityList= (start_temp, stop_temp) =>{
    axios
    .get(API_IP + `/Relationsnews?start=${start_temp}&stop=${stop_temp}`, CONFIG)
    .then(function (response) {
      setactivity(response.data[1]);
      setactivityAll(response.data[0])
    })
    .catch(function (error) {
    })
  }

  
  return (
    <div>
      <NavB />
      <CarouselMain data={banner} flag_banner={true}/>
      <Container>
        <MainTopicText state={i18n.language} name={t('navbar.activity')} />
        <Row md={4}>
          {activity == 0 ? (
              <div></div>
          ) : (
          activity.map((item, index) => (
            <Col xs={12} key={index}>
              <NewsCard data={item} link={item.relationsnews_id} from={'relationsnews'}/>
            </Col>
          ))
          )
          }
        </Row>
      </Container>

      <Box>
        <PaginationCustom>
          <SkipBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={lastButton} alt="last" onClick={()=> onFirstPage()}/>
          <NextBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={nextButton} alt="next" onClick={()=> onBack()}/>
          <NumberBox>
            {
              arrayPage.map((item, index)=>{
                return(
                  <Pagination.Item key={index}>
                    <div onClick={()=> pagenationClick(item)} style={currentPage == item.id?{color:'red',fontWeight:'bold'}:{}}>
                      {item.name}
                    </div>
                  </Pagination.Item>
                )
              })
            }
            
          </NumberBox>
          <NextBox src={nextButton} alt="next" style={{objectFit:'contain'}} onClick={()=> onNext()}/>
          <SkipBox src={lastButton} alt="last" style={{objectFit:'contain'}} onClick={()=> onLastPage()}/>
        </PaginationCustom>
      </Box>
      
      <Footer />
    </div>
  );
};

export default ActivityPage;
