import React, { useState, useEffect } from "react";
import HeaderRender from "modules/HeaderRender";
import { HEADER_STATES } from "modules/HeaderRender";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
const SearchBoardMember = (props) => {
  const { t } = useTranslation();
  const [DataList, setDataList] = useState([]);
  const [Position, setPosition] = useState('');
  const [Department, setDepartment] = useState('');
  const [PositionId, setPositionId] = useState(0);
  const [DepartmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
        var text = props.match.params.text
        var textId = props.match.params.textId
        var text_sp = text.split("_");
        var textId_sp = textId.split("_");
        if(text_sp[0] != 'NA'){
            setPosition(text_sp[0])
        }
        if(text_sp[1] != 'NA'){
            setDepartment(` ${text_sp[1]}`)
        }
        var p = ''
        var d = ''
        if(textId_sp[0] != 'NA'){
            setPositionId(textId_sp[0])
            p = textId_sp[0]
        }
        if(textId_sp[1] != 'NA'){
            setDepartmentId(`${textId_sp[1]}`)
            d = textId_sp[1]
        }
        console.log(`/Board/search/?department_id=${d}&board_id=${p}`)
        axios
        .get(API_IP + `/Board/search/?department_id=${d}&board_id=${p}`, CONFIG)
        .then(function (response) {
          if(response.data.length > 0){
            setDataList(response.data)
          }
        })
        .catch(function (error) {
        })
    };
    fetchData();
  }, []);

  // console.log('SearchBoardMember 88888', DataList)
  return (
    <div>
      <NavB />
      <HeaderRender
        numHeader={'SearchBoardMember'}
        name={`${Position}${Department}`}
        data={DataList}
        data2={0}
        data3={0}
      />
      <Footer />
    </div>
  );
};

export default SearchBoardMember;
