import React, { useState, useEffect } from "react";
import HeaderRender from "modules/HeaderRender";
import { HEADER_STATES } from "modules/HeaderRender";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
const SearchtopBoardMember = (props) => {
  const { t } = useTranslation();
  const [broadMembers, setBroadMembers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + `/Board/searchlike?text=${props.match.params.text}`, CONFIG)
      .then(function (response) {
        setBroadMembers(response.data);
        // console.log('kkkkkkkkk', response.data);
      })
      .catch(function (error) {
      })

     
    };
    
    fetchData();
  }, []);

  // console.log('SearchBoardMember 88888', DataList)
  return (
    <div>
      <NavB />
      <HeaderRender
        numHeader={1}
        name={props.match.params.text}
        data2={broadMembers}
        data={0}
        data3={0}
      />
      <Footer />
    </div>
  );
};

export default SearchtopBoardMember;
