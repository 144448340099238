import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  PicBoxFix,
  TopicCenter
} from "./styled";
import { useTranslation } from "react-i18next";
const NewsCardPrevious = ({ data, link }) => {
  const { t,i18n } = useTranslation();
  var title = ''
  if(i18n.language == 'en'){
    title = data.title_en
  }else if(i18n.language == 'th'){
    title = data.title_th
  }else if(i18n.language == 'ch'){
    title = data.title_cn
  }
  // console.log('test test',data )
  return (
    <a target={'_blank'} href={'http://'+data.url_company}>
      <Card style={{height:'200px'}}>
        <PicBoxFix style={{objectFit:'contain'}} src={data.url_img} />
        <TopicCenter style={{height:'40%'}}>
          <div>{title}</div>
        </TopicCenter>
      </Card>
    </a>
  );
};

export default NewsCardPrevious;
