import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavbarCustom, NavCustom, NavDropdownCustom } from "./styled";
import TopicNav from "../TopicNav";
import MediaQuery from "react-responsive";
import NavSlide from "modules/NavSlide";
import { useTranslation } from "react-i18next";
const NavB = () => {
  const { t } = useTranslation();
    const title_style = {
      fontSize:'1vw',
      textAlign:'center',
      marginLeft:'1vw',
      marginRight:'1vw'
    };
    const item_style = {
      fontSize:'1vw',
    };
    return (
      <div style={{ fontFamily: "promptLight" }}>
        <MediaQuery maxDeviceWidth={991}>
          <NavSlide />
        </MediaQuery>

        <MediaQuery minDeviceWidth={992}>
          <TopicNav />
          <NavbarCustom defaultactivekey="/" expand="lg">
            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-center"
            >
              <NavCustom style={{width:'70%',justifyContent:'center'}}>
                <Nav.Link style={title_style} href="/home">{t('navbar.home')}</Nav.Link>
                <NavDropdownCustom style={title_style} title={t('navbar.about')}>
                  <NavDropdownCustom.Item href="/visionmission" style={item_style} col>{t('navbar.dropdown.vision_mission')}</NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/about" style={item_style} col>{t('navbar.dropdown.about_thaichinese')}</NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/president" style={item_style}>{t('navbar.dropdown.about_president_thaichinese')}</NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/boardMember" style={item_style}>{t('navbar.dropdown.board_member')}</NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/honorMember" style={item_style}>{t('navbar.dropdown.hornor_member')}</NavDropdownCustom.Item>
                </NavDropdownCustom>
                {/* <Nav.Link style={title_style} href="/activity">{t('navbar.activity')}</Nav.Link> */}
                <NavDropdownCustom style={title_style} title={t('navbar.news')}>
                  <NavDropdownCustom.Item href="/news" style={item_style}>
                    {t('navbar.dropdown.activity')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/activity" style={item_style} col>
                    {t('navbar.dropdown.relation')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/media" style={item_style}>
                    {t('navbar.dropdown.relation_media')}
                  </NavDropdownCustom.Item>
                </NavDropdownCustom>
                <NavDropdownCustom style={title_style} title={t('navbar.economy')}>
                  <NavDropdownCustom.Item href="/business" style={item_style}>
                  {t('navbar.dropdown.ThaiChinese_articles')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/confident" style={item_style}>
                  {t('navbar.dropdown.ThaiChinese_belief')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/ThaiInvest" style={item_style}>
                  {t('navbar.dropdown.Capital_investment_thai')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/ChineseInvest" style={item_style}>
                  {t('navbar.dropdown.Capital_investment_china')}
                  </NavDropdownCustom.Item>
                </NavDropdownCustom>
                <NavDropdownCustom  style={title_style}title={t('navbar.member')}>
                 
                  <NavDropdownCustom.Item href="/member" style={item_style}>
                  {t('navbar.dropdown.member')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/register" style={item_style}>
                  {t('navbar.dropdown.register')}
                  </NavDropdownCustom.Item>
                </NavDropdownCustom>
                <NavDropdownCustom  style={title_style}title={t('navbar.department')}>
                  <NavDropdownCustom.Item href="/depathment/Entrepreneurs_Convention" style={item_style}>
                  {t('navbar.dropdown.Entrepreneurs_Convention')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/depathment/Joint_Foreign" style={item_style}>
                  {t('navbar.dropdown.Joint_Foreign')}
                  </NavDropdownCustom.Item>
                  <NavDropdownCustom.Item href="/depathment/ThaiCC_Related" style={item_style}>
                  {t('navbar.dropdown.ThaiCC_Related')}
                  </NavDropdownCustom.Item>
                </NavDropdownCustom>
                <Nav.Link style={title_style} href="/contact">{t('navbar.contactus')}</Nav.Link>
              </NavCustom>
            </Navbar.Collapse>
          </NavbarCustom>
        </MediaQuery>
      </div>
    );
  }

export default NavB;
