import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import DetailText from "modules/DetailText";
import {
    RowCustom
} from "./styled";
import NewsChamber from "modules/NewsChamber";
const DepathmentPage = (props) => {
    const { t, i18n } = useTranslation();
    const [aboutbanner, setaboutbanner] = useState([]);
    const [aboutbannerAll, setaboutbannerAll] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);

    const [start, setstart] = useState(0);
    const [stop, setstop] = useState(23);
    useEffect(() => {
      const fetchData = async () => {
        var url = ''
        if(props.match.params.name == 'Entrepreneurs_Convention'){
          url = "/About/banner?id=1"
        }else if(props.match.params.name == 'Joint_Foreign'){
          url = "/About/banner?id=2"
        }else if(props.match.params.name == 'ThaiCC_Related'){
          url = "/About/banner?id=3"
        }
        axios
        .get(API_IP + url, CONFIG)
        .then(function (response) {
            setaboutbanner(response.data);
            setaboutbannerAll(response.data)
            console.log(response.data);
        })
        .catch(function (error) {
        })
      };
      fetchData();
    }, []);
    var title = ''
    if(props.match.params.name == 'Entrepreneurs_Convention'){
        title = t('navbar.dropdown.Entrepreneurs_Convention')
    }else if(props.match.params.name == 'Joint_Foreign'){
        title = t('navbar.dropdown.Joint_Foreign')
    }else if(props.match.params.name == 'ThaiCC_Related'){
        title = t('navbar.dropdown.ThaiCC_Related')
    }

    // var perPage = 24
    // var arrayPage = []
    // var page = aboutbannerAll.length/perPage
    // for(var i = 0 ; i < page ; i++){
    //   var item = {
    //     id:i+1,
    //     name:i+1
    //   }
    //   arrayPage.push(item)
    // }
  
    // const pagenationClick = (item) => {
    //   var index = item.id
    //   var start_temp = 0
    //   var stop_temp = 0
    //   setcurrentPage(index)
    //   stop_temp = (index*perPage)-1
    //   start_temp = (stop_temp+1)-perPage
    //   console.log('pagenationClick',index,start_temp, stop_temp)
    //   getBannerList(start_temp,stop_temp)
    
    // };

    // const onNext = () => {
    //   var page = currentPage + 1
    //   setcurrentPage(currentPage + 1)
    //   var start_temp = 0
    //   var stop_temp = 0
    //   setcurrentPage(page)
    //   stop_temp = (page*perPage)-1
    //   start_temp = (stop_temp+1)-perPage
    //   getBannerList(start_temp,stop_temp)
    // }
    
    // const onBack = () => {
    //   var page = currentPage - 1
    //   setcurrentPage(currentPage - 1)
    //   var start_temp = 0
    //   var stop_temp = 0
    //   setcurrentPage(page)
    //   stop_temp = (page*perPage)-1
    //   start_temp = (stop_temp+1)-perPage
    //   getBannerList(start_temp,stop_temp)
    // }

    // const onFirstPage = () => {
    //   var page = 1
    //   setcurrentPage(1)
    //   var start_temp = 0
    //   var stop_temp = 0
    //   setcurrentPage(page)
    //   stop_temp = (page*perPage)-1
    //   start_temp = (stop_temp+1)-perPage
    //   getBannerList(start_temp,stop_temp)
    // }

    // const onLastPage = () => {
    //   var page = arrayPage[arrayPage.length-1].id
    //   setcurrentPage(1)
    //   var start_temp = 0
    //   var stop_temp = 0
    //   setcurrentPage(page)
    //   stop_temp = (page*perPage)-1
    //   start_temp = (stop_temp+1)-perPage
    //   getBannerList(start_temp,stop_temp)
    // }

    // const getBannerList= (start_temp, stop_temp) =>{
    //   axios
    //   .get(API_IP + `/About/banner?id=1&start=${start_temp}&stop=${stop_temp}`, CONFIG)
    //   .then(function (response) {
    //     setactivity(response.data[1]);
    //     setactivityAll(response.data[0])
    //   })
    //   .catch(function (error) {
    //   })
    // }
    return (
      <div>
        <NavB />
          <Container>
            <DetailText 
                state={i18n.language}
                name={title}
                />
            <RowCustom md={4}>
            {
                aboutbanner.map((item, index)=>{
                    return(
                    <Col xs={12}>
                        <NewsChamber data={item}/>
                    </Col>
                    )
                })
            }
            </RowCustom>
          </Container>
          {/* <Box>
            <PaginationCustom>
              <SkipBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={lastButton} alt="last" onClick={()=> onFirstPage()}/>
              <NextBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={nextButton} alt="next" onClick={()=> onBack()}/>
              <NumberBox>
                {
                  arrayPage.map((item, index)=>{
                    return(
                      <Pagination.Item key={index}>
                        <div onClick={()=> pagenationClick(item)} style={currentPage == item.id?{color:'red',fontWeight:'bold'}:{}}>
                          {item.name}
                        </div>
                      </Pagination.Item>
                    )
                  })
                }
                
              </NumberBox>
              <NextBox src={nextButton} alt="next" style={{objectFit:'contain'}} onClick={()=> onNext()}/>
              <SkipBox src={lastButton} alt="last" style={{objectFit:'contain'}} onClick={()=> onLastPage()}/>
            </PaginationCustom>
          </Box> */}
        <Footer />
      </div>
    );
  };
  
  export default DepathmentPage;