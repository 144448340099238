import styled from "styled-components";

export const TopicBox = styled.div`
  margin-bottom: 30px;
`;

export const PicBox = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TextBox = styled.div`
  font-family: promptLight;
  color: #0a2980;
  font-size: 30px;
  @media (max-width: 1024px) {
    top: 3.9vw;
  }
  top: 40px;

  position: relative;
`;

export const TextBox2 = styled(TextBox)`
  font-family: cinzel;
  letter-spacing: 5px;
`;
