import React, { useState, useEffect } from "react";
import RenderBusinessPage from "modules/RenderBusinessPage";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import NavB from "modules/NavB";
import Footer from "modules/Footer";

const DetailNewsPage = (props) => {
  // console.log('DetailNewsPage',props.match.params.news,props.match.params.id)
  
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [DetailNews, setDetailNews] = useState([]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    const fetchData = async () => {
      // console.log('props.match.params.news',props.match.params.news)
      var name = ''
      if(props.match.params.news == 'activity'){
        name = 'Activitynews'
      }else if(props.match.params.news  == 'relationsnews'){
        name = 'Relationsnews'
      }
      else if(props.match.params.news == 'media'){
        name = 'Relationsnews/media'
      }
      else if(props.match.params.news == 'articles'){
        name = 'Economy/ThaiChinese_articles'
      }
      else if(props.match.params.news == 'belief'){
        name = 'Economy/ThaiChinese_belief'
      }
      else if(props.match.params.news == 'investmentTH'){
        name = 'Economy/Capital_investment_thai'
      }
      else if(props.match.params.news == 'investmentCHI'){
        name = 'Economy/Capital_investment_china'
      }
      // console.log('name1',name)
      axios
        .get(API_IP + `/${name}?start=0&stop=0`, CONFIG)
        .then(function (response) {
          // console.log('response',response.data)
          var array1 = response.data[0]
          if(props.match.params.news == 'activity'){
            var found = array1.find(element => element.activitynews_id == props.match.params.id);
          }else if(props.match.params.news == 'relationsnews'){
            var found = array1.find(element => element.relationsnews_id == props.match.params.id);
          }
          else if(props.match.params.news == 'media'){
            var found = array1.find(element => element.media_id == props.match.params.id);
          }
          else if(props.match.params.news == 'articles'){
            var found = array1.find(element => element.business_economy_id == props.match.params.id);
          }
          else if(props.match.params.news == 'belief'){
            var found = array1.find(element => element.thai_chinese_belief_id == props.match.params.id);
          }
          else if(props.match.params.news == 'investmentTH'){
            var found = array1.find(element => element.capital_investment_thai_id == props.match.params.id);
          }
          else if(props.match.params.news == 'investmentCHI'){
            var found = array1.find(element => element.capital_investment_china_id == props.match.params.id);
          }
          // console.log('found',found)
          setDetailNews(found);
        })
        .catch(function (error) {
        })
      
    };
    fetchData();
  }, []);
// console.log('DetailNews',DetailNews)
  const { t,i18n } = useTranslation();
  var title = ''
  if(i18n.language == 'en'){
    title = DetailNews.title_en
  }else if(i18n.language == 'th'){
    title = DetailNews.title_th
  }else if(i18n.language == 'ch'){
    title = DetailNews.title_cn
  }
  const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };
  return (
    <div>
      <NavB />
        <div style={{margin: '30px 0'}}>
          {DetailNews &&<RenderBusinessPage namePage={title} DetailNews={DetailNews} />}
        </div>
          <Footer />
        </div>
  );
};

export default DetailNewsPage;
