import styled from "styled-components";
import { Navbar } from "react-bootstrap";

export const NavCustom = styled(Navbar)`
  background-color: #fdd600;
  .dropdown-toggle::after {
    margin-left: 10px;
    margin-top: px;
  }

  .nav-link {
    color: black !important;
  }
  .dropdown-menu {
    background-color: #fdde32;
    border: none;
  }
  .navbar-brand {
    padding-top: 0rem;
    padding-bottom: 0rem;
    margin-right: 1rem;
  }
`;

export const PicBox = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`

export const Box = styled.div`
  width: fit-content;
  font-size: 20px;
  padding-top: 15px;
`;

export const LanBox = styled.div`
    height: fit-content;
    font-size: 16px;
    width: 50px;
    background-color: rgba(185, 171, 96, 0.5);
    color: white;
    border-radius: 20px;
    text-align: center;
    font-weight: bold; 
`
