import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainTopicText from "modules/MainTopicText";
import DetailText from "modules/DetailText";
import ButtonPush from "modules/ButtonPush";
import { ImLocation2, ImPhone } from "react-icons/im";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaSearch } from 'react-icons/fa'
import { API_IP, CONFIG, URL } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { SearchTag, SelectTag, ButtonSearch, BottonTabAn, NCompany, PictureScope } from "./styled";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';
const SearchCompany = (props) => {
  const { t, i18n } = useTranslation();
  const [TypeCompany, setTypeCompany] = useState([]);
  const [NameCompany, setNameCompany] = useState([]);
  // const [namesearch2, setnamesearch] = useState('null');
  // const [typesearch2, settypesearch] = useState('null');
  var type ;
  var namesearch ;
  var searchtext = props.match.params.searchtext;
  var com = '';
  if(props.match.params.lang == 'th'){
    com = 'บริษัท ';
  }
  let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
  }
  if(props.match.params.id !== "null"){
      type = props.match.params.id;
  }else{
      type = "";
  }

  if(props.match.params.searchname !== "null"){
      namesearch = props.match.params.searchname;
  }else{
      namesearch = "";
  }
  
  useEffect(() => {
    
    const TypeCompany = async () => {
      axios
        .get(API_IP + "/Company/select", CONFIG)
        .then(function (response) {
          console.log('TypeCompany res',response.data)
          setTypeCompany(response.data);
        })
        .catch(function (error) {
        })
        
       
    };
    const FetchProfile = async () => {
        axios
          .get(API_IP + `/Company/search?text=${com}${searchtext}&name=${namesearch}&category_id=${type}`, CONFIG)
          .then(function (response) {
            console.log('NameCompany res',response.data)
            setNameCompany(response.data);
          })
          .catch(function (error) {
          })
         
      };
    TypeCompany();
    FetchProfile();
    
  }, []);

  // function handleChange(e) {
  //   setnamesearch(e.target.value);
  // }

  // function handleChange2(e) {
  //   settypesearch(e.target.value);
  // }

  // function buttonsearch(){
  //     if(typesearch2 !== "null" || namesearch2 !== "null" ){
  //       history.push({ pathname: "/searchMember/"+typesearch2+"/"+namesearch2+"/null" })
  //     }else{
  //       swal("กรุณากรอกคำค้นหา หรือ เลือกประเภทธุรกิจ");
  //     }
  // }

  return (
    <div>
    <NavB />
    <Container>
      
      <MainTopicText state={i18n.language} name={t('navbar.dropdown.member')} />
      {/* <Row style={{height:'100%', justifyContent: 'center'}}>
        <Col md={4} sm={4} xs={8} style={{paddingRight: '2px', paddingLeft: '0px', marginBottom: '5px'}}>
          <SearchTag type="search" name="" id="input"  placeholder="คำค้นหา"  title=""  onChange={handleChange} />
        </Col>
        <Col md={4} sm={4} xs={8} style={{paddingLeft: '0px', paddingRight: '2px', marginBottom: '5px'}}>
          <SelectTag onChange={handleChange2}>
            <option selected value="*">ประเภทธุรกิจ</option>
          {
          TypeCompany.map((item, index)=>{
            return(
            <option  value={item.company_id}>{item.name_th}</option>
            )
          })
        }
          </SelectTag>
        </Col>
        <Col md={1} sm={1} xs={8} style={{padding:'0px', marginBottom: '5px'}}>
            <ButtonSearch  type="submit"><FaSearch style={{color:'#020d5d'}}
                                          onClick={buttonsearch}/>
            </ButtonSearch>
        </Col>
      </Row> */}

      <br/>
      {
          TypeCompany.map((item, index)=>{
            if(type === item.company_id && type !== "*" && type !== ""){
              var name_company = ''
              if(i18n.language == 'th'){
                name_company = item.name_th
              }else if(i18n.language == 'en'){
                name_company = item.name_en
              }else if(i18n.language == 'ch'){
                name_company = item.name_cn
              }
            return(
            <DetailText state ='th' name={name_company} />
            )
            }
          })
      }

      <Row md={4}>
      {
          NameCompany.map((item, index)=>{
            var company =''
            var location =''
            if(i18n.language == 'th'){
              company = item.company_th
              location = item.location_th
            }else if(i18n.language == 'en'){
              company = item.company_en
              location = item.location_en
            }else if(i18n.language == 'ch'){
              company = item.company_cn
              location = item.location_cn
            }
            return(
            <Col xs={12} style={{marginBottom: '20px'}}>
                <Link  to={{pathname: "/InfoCompany/"+item.profile}}>
                  <PictureScope>
                  
                    <div style={{maxHeight: "175px"}}>
                    <img  style={{maxHeight: "175px",maxWidth: "175px",width: "100%"}} src={item.img_company}/>
                    </div>
                  
                  </PictureScope>
                    <NCompany md={10}>{company}</NCompany>
                </Link>
                    <Row style={{color:'#918338',fontFamily: 'promptLight'}}>
                    <Col md={1} ><ImLocation2 /></Col>
                    <Col md={10}>{location}</Col>
                    <Col md={1} ><ImPhone /></Col>
                    <Col md={10}>{item.phone_company}</Col>
                    </Row>
                
            </Col>
            )
          })
        }
          
      </Row>
      <BottonTabAn onClick={goToPreviousPath}>
        <ButtonPush name={t('MemberDirectory.goBack')}/>
      </BottonTabAn>
      <br/>
      
      {/* <PaginationTab perPage={1} currentPage={1} data={members}/> */}
      
    </Container>
    <Footer />
    </div>
  );
};

const styles = {

  dataCole: {
    paddingBottom: '25px'
  },

  dataA: { 
    paddingBottom: '20px',
    color: '#152d83',
    fontFamily: 'promptLight',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  }

}

export default SearchCompany;
