import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import HomePage from "./HomePage";
import ActivityPage from "./ActivityPage";
import InformationPage from "./InformationPage";
import MediaPage from "./MediaPage";
import ThaiChineseBusiness from "./ThaiChineseBusiness";
import ThaiChineseConfident from "./ThaiChineseConfident";
import ThaiInvest from "./ThaiInvest";
import ChineseInvest from "./ChineseInvest";
import BoardMember from "./BoardMember";
import HonorMember from "./HonorMember";
import MemberDirectory from "./MemberDirectory";
import SearchCompany from "./MemberDirectory/searchCompany";
import InfoCompany from "./MemberDirectory/informationCompany";
import AboutPage from "./AboutPage";
import PresidentPage from "./PresidentPage"
import ContactPage from "./ContactPage";
import MemberLibrary from "./MemberLibrary";
import ScrollTop from "./ScrollTop";
import DetailNewsPage from "./DetailNewsPage";
import LandingPage from './LandingPage';
import MemberRegister from './MemberRegister';
import DepathmentPage from './DepathmentPage';
import SearchBoardMember from './SearchBoardMember';
import SearchtopBoardMember from './SearchtopBoardMember';
import Visionmission from './VisionmissionPage';
const Routes = () => {
  return (
    <Router>
      <ScrollTop>

        <Route exact path="/" component={LandingPage} />
        <Route path="/home" component={HomePage} />
        <Route path="/activity" component={InformationPage} />
        <Route path="/news" component={ActivityPage} />
        <Route path="/media" component={MediaPage} />
        <Route path="/business" component={ThaiChineseBusiness} />
        <Route path="/confident" component={ThaiChineseConfident} />
        <Route path="/ThaiInvest" component={ThaiInvest} />
        <Route path="/ChineseInvest" component={ChineseInvest} />
        <Route path="/boardMember" component={BoardMember} />
        <Route path="/honorMember" component={HonorMember} />
        <Route path="/member" component={MemberDirectory} />
        <Route path="/searchMember/:id/:searchname/:searchtext/:lang" component={SearchCompany} />
        <Route path="/InfoCompany/:info" component={InfoCompany} />
        <Route path="/visionmission" component={Visionmission} />
        <Route path="/about" component={AboutPage} />
        <Route path="/president" component={PresidentPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/MemberLibrary/:name" component={MemberLibrary} />
        <Route path="/register" component={MemberRegister} />
        <Route path="/DetailNews/:news/:id" component={DetailNewsPage} />
        <Route path="/depathment/:name" component={DepathmentPage} />
        <Route path="/search/:text/:textId" component={SearchBoardMember} />
        <Route path="/searchtop/:text" component={SearchtopBoardMember} />
      </ScrollTop>
    </Router>
  );
};

export default Routes;
