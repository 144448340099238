import React from 'react'
import { FooterBox } from './styled'
const Footer = () => {
    return (
        <FooterBox>
            <div>
                <div>43 อาคารไทย ซี.ซี. ชั้น 9 ถนนสาทรใต้ แขวงยานนาวา เขตสาทร กรุงเทพฯ 10120</div> 
                <div>43 Thai C.C. Tower 9th Fl.,South Sathon Rd., Yannawa, Sathon, Bangkok 10120, Thailand</div>
            </div>
        </FooterBox>
    )
}

export default Footer