import styled from "styled-components";

export const TextBox = styled.div`
  font-family: "promptLight";
  width: 100%;
  text-align: left;
  font-size: 25px;
  font-weight: bold;
  color: #0a2980;
  border-bottom: 0.1389vw solid #b9ab60;
  @media (min-width: 1024px) {
    margin-bottom: 10px;
  }
`;
