import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import MainTopicText from "modules/MainTopicText";
import PaginationTab from "modules/PaginationTab";
import { Container, Row, Col, Pagination } from "react-bootstrap";
import NewsCard from "modules/NewsCard";
import facebook from "modules/images/facebook.svg";
import twitter from "modules/images/twitter.svg";
import line from "modules/images/line.svg";
import Mediaquery from "react-responsive";
import {
  PicBox,
  Card,
  Topic,
  UnderLineBox,
  RedLine,
  BlackLine,
  BodyBox,
  ShareBox,
  ButtonCustom,
  SocialPic,
  PaginationCustom, NextBox, SkipBox, NumberBox, Box
} from "./styled";
import axios from "axios";
import { API_IP, CONFIG, month_th, month_en, day_th, day_en } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import CarouselMain from "modules/CarouselMain";
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import nextButton from "modules/images/nextButton.svg";
import lastButton from "modules/images/lastButton.svg";
const MediaPage = ({ nameTopicM, url }) => {
  const [media, setmedia] = useState([]);
  const [mediaAll, setmediaAll] = useState([]);
  const [banner, setbanner] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);

  const [start, setstart] = useState(0);
  const [stop, setstop] = useState(23);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + `/Relationsnews/media?start=${start}&stop=${stop}`, CONFIG)
      .then(function (response) {
        setmedia(response.data[1]);
        setmediaAll(response.data[0]);
      })
      .catch(function (error) {
      })

      axios
      .get(API_IP + "/banner", CONFIG)
      .then(function (response) {
        setbanner(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);

  var perPage = 24
  var arrayPage = []
  var page = mediaAll.length/perPage
  for(var i = 0 ; i < page ; i++){
    var item = {
      id:i+1,
      name:i+1
    }
    arrayPage.push(item)
  }
  
  const pagenationClick = (item) => {
    var index = item.id
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(index)
    stop_temp = (index*perPage)-1
    start_temp = (stop_temp+1)-perPage
    console.log('pagenationClick',index,start_temp, stop_temp)
    getMediaList(start_temp,stop_temp)
   
  };

  const onNext = () => {
    var page = currentPage + 1
    setcurrentPage(currentPage + 1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getMediaList(start_temp,stop_temp)
  }
  
  const onBack = () => {
    var page = currentPage - 1
    setcurrentPage(currentPage - 1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getMediaList(start_temp,stop_temp)
  }

  const onFirstPage = () => {
    var page = 1
    setcurrentPage(1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getMediaList(start_temp,stop_temp)
  }

  const onLastPage = () => {
    var page = arrayPage[arrayPage.length-1].id
    setcurrentPage(1)
    var start_temp = 0
    var stop_temp = 0
    setcurrentPage(page)
    stop_temp = (page*perPage)-1
    start_temp = (stop_temp+1)-perPage
    getMediaList(start_temp,stop_temp)
  }

  const getMediaList= (start_temp, stop_temp) =>{
    axios
    .get(API_IP + `/Relationsnews/media?start=${start_temp}&stop=${stop_temp}`, CONFIG)
    .then(function (response) {
      setmedia(response.data[1]);
      setmediaAll(response.data[0])
    })
    .catch(function (error) {
    })
  }

  const { t,i18n } = useTranslation();
    if(media.length > 0){
      var date = moment(media[0].created_date,'YYYY-MM-DD HH:mm:ss')
      var month = date.format('M')
      var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
      var year = date.year()
      var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
      var days = date.format('D')
      var dateshow = ''
      var title = ''
      if(i18n.language == 'en'){
        title = media[0].title_en
        dateshow = `${yeartxt}/${month}/${days}`
      }else if(i18n.language == 'th'){
        title = media[0].title_th
        dateshow = `${days}/${month}/${yeartxt}`
      }else if(i18n.language == 'ch'){
        title = media[0].title_ch
        dateshow = `${yeartxt}/${month}/${days}`
      }
    }
    const shareUrl = 'http://github.com';
    const title_test = 'GitHub';
  return (
    <div>
      <NavB />
      <CarouselMain data={banner} flag_banner={true}/>

      <Container>
       
        <MainTopicText state={i18n.language} name={t('navbar.dropdown.relation_media')} />
        <Row>
          {media.length > 0 &&<Col>
            <PicBox>
              <ReactPlayer
                style={{ position: "absolute", top: "0", left: "0" }}
                width="100%"
                height="100%"
                controls
                url={media[0].link}
              />
            </PicBox>
            <Card>
              <Topic>
                <div>{title}</div>
                <UnderLineBox>
                  <RedLine />
                  <BlackLine />
                </UnderLineBox>
              </Topic>

              <Mediaquery minWidth={1024}>
              <ShareBox>
                  
                  <div>{dateshow}</div>
                  {/* <FacebookShareButton
                    url={window.location.href}
                    quote={title_test}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#3B5999" }}>
                      <SocialPic src={facebook} alt="facebook" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom> 
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={title_test}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#03A9F4" }}>
                      <SocialPic src={twitter} alt="twitter" />
                      <div>Tweet</div>
                    </ButtonCustom>
                  </TwitterShareButton>
                  
                  <LineShareButton
                    url={window.location.href}
                    title={title_test}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#00c300" }}>
                      <SocialPic src={line} alt="line" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom>
                  </LineShareButton> */}
                  
                </ShareBox>
              </Mediaquery>

              <BodyBox>
                {media[0].short_description_th}
              </BodyBox>
            </Card>
          </Col>}
        </Row>
        <Row md={4}>
          {media == 0 ? (
              <div></div>
          ) : (
            media.map((item, index)=>{
              return(
                <Col xs={12} key={index}>
                  <NewsCard data={item} link={item.media_id} from={'media'}/>
                </Col>
              )
            })
          )}
          
         
        </Row>
      </Container>

      <Box>
          <PaginationCustom>
            <SkipBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={lastButton} alt="last" onClick={()=> onFirstPage()}/>
            <NextBox style={{ transform: "rotate(180deg)" ,objectFit:'contain'}} src={nextButton} alt="next" onClick={()=> onBack()}/>
            <NumberBox>
              {
                arrayPage.map((item, index)=>{
                  return(
                    <Pagination.Item key={index}>
                      <div onClick={()=> pagenationClick(item)} style={currentPage == item.id?{color:'red',fontWeight:'bold'}:{}}>
                        {item.name}
                      </div>
                    </Pagination.Item>
                  )
                })
              }
              
            </NumberBox>
            <NextBox src={nextButton} alt="next" style={{objectFit:'contain'}} onClick={()=> onNext()}/>
            <SkipBox src={lastButton} alt="last" style={{objectFit:'contain'}} onClick={()=> onLastPage()}/>
          </PaginationCustom>
      </Box>
      <Footer />
    </div>
  );
};

export default MediaPage;
