import styled from "styled-components";

export const PicBox = styled.img`
  width: 100%;
  margin: 20px 0;
`;

export const ShareBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 450px;
  font-family: "promptLight";
  position: relative;
`;

export const ButtonCustom = styled.div`
  width: fit-content;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-right: 20px;
`;

export const SocialPic = styled.img`
  height: 20px;
  margin: 0 10px 0 10px;
  object-fit: cover;
  object-fit: cover;
`;

export const ButtonDate = styled(ButtonCustom)`
  border: 2px solid #b9ab60;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0px;
  width: 120px;
  color: #0a2980;
`;

export const Detail = styled.div`
  font-family: promptLight;
  margin-bottom: 25px;
`;

export const Picrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const Picinrow = styled.img`
  width: 70px;
  height: auto;
  margin-right: 5px;
  object-fit: cover;
`

export const BottonTabAn = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
`
export const RedLine = styled.div`
    background-color: #a00a08;
    height: 2.2px;
    width: 110px;
    bottom: 0;
    position: relative;
`

export const BlackLine = styled.div`
    background-color: #000;
    height: 2.2px;
    width: 20px;
    position: relative;
`
export const UnderLineBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 135px;
    margin-top: 7px;
`;