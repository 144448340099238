export const th = {
  "navbar": {
    "home":"หน้าแรก",
    "news":"กิจกรรม",
    "activity":"ข่าวประชาสัมพันธ์",
    "economy":"ข้อมูลเศรษฐกิจ",
    "member":"สมาชิก",
    "about":"เกี่ยวกับเรา",
    "contactus":"ติดต่อเรา",
    "department":"หน่วยงานที่เกี่ยวข้อง",
    "dropdown":{
      "vision_mission":"วิสัยทัศน์และพันธกิจ",
      "about_thaichinese":"ประวัติความเป็นมา",
      "about_president_thaichinese":"ประธานหอการค้าไทย-จีน",
      "relation":"กิจกรรม",
      "activity":"ข่าวประชาสัมพันธ์",
      "relation_media":"MEDIA",
      "ThaiChinese_articles":"บทความธุรกิจไทย - จีน",
      "ThaiChinese_belief":"ดัชนีความเชื่อมั่นทางเศรษฐกิจ",
      "Capital_investment_thai":"การลงทุนในไทย",
      "Capital_investment_china":"การลงทุนในจีน",
      "board_member":"ทำเนียบคณะกรรมการ",
      "hornor_member":"ทำเนียบกิตติมศักดิ์",
      "member":"ทำเนียบสมาชิก",
      "register":"สมัครสมาชิก",
      "Entrepreneurs_Convention":"คณะกรรมการที่ปรึกษาการประชุมผู้ประกอบการชาวจีนโลก",
      "Joint_Foreign":"หอการค้าร่วมต่างประเทศในประเทศไทย",
      "ThaiCC_Related":"สมาพันธ์หอการค้าไทย-จีน และสมาคมธุรกิจต่างๆ",
    }
  },
  "register":{
    "member":"ส่วนของสมาชิก",
    "register":"ใบสมัครสมาชิก",
    "download":"ดาวน์โหลดเอกสาร"
  },
  "vision_mission":{
    "vision":'วิสัยทัศน์',
    "mission":"พันธกิจ"
  },
  "about":{
    "chamber_of_commerce_network":'CHAMBER OF COMMERCE NETWORK',
    "History_title":"ประวัติหอการค้าไทย-จีน"
  },
  "president":{
    "president":"ประธานคนปัจจุบัน",
    "president_previous":"อดีตประธานหอการค้าไทย-จีน"
  },
  "boardMember":{
    "header1":"สมัยที่ 28",
    "header2":"คณะกรรมการ",
    "vice_president":"รองประธานกรรมการ",
    "secretary_general":"กรรมการเลขาธิการ",
    "deputy_secretary_general":"กรรมการรองเลขาธิการ",
    "assistant_secretary_general":"ผู้ช่วยกรรมการเลขาธิการ",
    "relation":"หอการค้าไทยจีน",
    "more":"MORE",
    "president":"ประธานหอการค้าไทย - จีน",
    "and": "และ"
  },
  "home":{
    "vision_mission":"วิสัยทัศน์และพันธกิจ",
    "about":"ประวัติความเป็นมา",
    "economyThaiChinese":"เศรษฐกิจไทย - จีน",
    "highlight":"ไฮไลท์",
    "ThaiChinese_belief":"ดัชนีความเชื่อมั่น",
    "relation":"หอการค้าไทยจีน",
    "more":"MORE",
    "president":"ประธานหอการค้าไทย - จีน",
  },
  "honor":{
    "status":"ประธานกิตติมศักดิ์ผู้ทรงเกียรติ"
  },
  "socialMedia":{
    "share":"แชร์"
  },
  "searchBoardMember":{
    "title":"ทำเนียบคณะกรรมการ สมัยที่ 28",
    "topsearch":"ผลการค้นหา"
  },
  "MemberDirectory":{
    "type_company":"ประเภทธุรกิจ",
    "placeholder" :"คำค้นหา",
    "goBack": "กลับ"
  }
}