import React, { useState, useEffect } from "react";
import background from "modules/images/background.jpg";
import head from "modules/images/head.png";
import {
  PicNews,
  ContainerName,
  NameThai,
  NameEng,
  PositionEng,
  RowCustom,
} from "./styled";
import DetailTextbule from "modules/DetailTextbule";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import SecondTopicTextYellow from "modules/SecondTopicTextYellow";
import NewsCardPrevious from "modules/NewsCardPrevious";
import preriousTest from "modules/images/preriousTest.png"
const PresidentPage = () => {
  const [OldPresident, setOldPresident] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Old_president", CONFIG)
      .then(function (response) {
        setOldPresident(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);

  const { t,i18n } = useTranslation();
  if(OldPresident.length > 0){
    var description_one = ''
    var description_two = ''
    var description_three = ''
    if(i18n.language == 'en'){
      description_one = OldPresident[0].description_one_en
      description_two = OldPresident[0].description_two_en
      description_three = OldPresident[0].description_three_en
    }else if(i18n.language == 'th'){
      description_one = OldPresident[0].description_one_th
      description_two = OldPresident[0].description_two_th
      description_three = OldPresident[0].description_three_th
    }else if(i18n.language == 'ch'){
      description_one = OldPresident[0].description_one_ch
      description_two = OldPresident[0].description_two_ch
      description_three = OldPresident[0].description_three_ch
    }
  }
  
  return (
    <div>
      <NavB />
      <Container>
        <DetailTextbule 
          state={i18n.language}
          name={t('president.president')}
        />
        {OldPresident.length > 0 &&<Row  style={{paddingBottom:'30px'}}>
          <Col md={3}>
            <PicNews src={OldPresident[0].url_img} alt="DetailPic" />
          </Col>
          <Col md={{ span: 9 }} style={{paddingLeft:'30px'}}>
            <ContainerName style={{paddingBottom:'10px',marginBottom:'10px'}}>
              <NameThai>{OldPresident[0].name_th+" "+OldPresident[0].lastname_th}</NameThai>
              <NameEng>{OldPresident[0].name_en+" "+OldPresident[0].lastname_en}</NameEng>
              <PositionEng>PRESIDENT</PositionEng>
            </ContainerName>
            <div dangerouslySetInnerHTML={{__html: description_one}}/>
          </Col>
        </Row>}
        <Row>
          <Col>
            <div dangerouslySetInnerHTML={{__html: description_two}}/>
          </Col>
        </Row>

        <SecondTopicTextYellow
            state={i18n.language}
            name={t('president.president_previous')}
        />
        <RowCustom md={4}>
          {
            OldPresident.map((item, index)=>{
              if(index != 0){
                return(
                  <Col xs={12}>
                    <NewsCardPrevious data={item}/>
                  </Col>
                )
              }
              
            })
          }
        </RowCustom>
      </Container>
      <Footer />
    </div>
  );
};

export default PresidentPage;
