import styled from "styled-components";
import { Pagination } from "react-bootstrap";

export const PicBox = styled.div`
  margin-bottom: 20px;
  position: relative;
  padding-top: 56.25%;
`;

export const Card = styled.div`
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 25px;
`;

export const BodyBox = styled.div`
  font-family: "promptLight";
`;

export const Topic = styled.div`
  font-family: "promptLight";
  font-size: 20px;
  color: #918338;
  font-weight: bold;
  margin: 10px 0 10px 0;
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const RedLine = styled.div`
  background-color: #a00a08;
  height: 2.2px;
  width: 110px;
  bottom: 0;
  position: relative;
`;

export const BlackLine = styled.div`
  background-color: #000;
  height: 2.2px;
  width: 20px;
  position: relative;
`;
export const UnderLineBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 135px;
  margin-top: 7px;
`;

export const ShareBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
  margin-bottom: 10px;
  font-family: "promptLight";
`;

export const ButtonCustom = styled.div`
  width: fit-content;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-right: 20px;
`;

export const SocialPic = styled.img`
    height: 20px;
    margin: 0 10px 0 10px;
    object-fit: cover;
`
export const PaginationCustom = styled(Pagination)`
  .page-link {
    color: #b9ab60;
    border: none;
    font-weight: 1000;
    font-size: 18px;
    padding: 8px 6px;
    @media (max-width: 767px) {
      font-size: 14px;
    }
    :hover {
      color: #b9ab60;
    }
  }
  height: 38px;
  width: 270px;
  @media (max-width: 767px) {
    width: 217px;
  }
`;

export const NextBox = styled.img`
  height: 36px;
  width: 32.91px;
  padding: 0 10px 0;
  @media (max-width: 767px) {
    height: 30px;
    width: 26.91px;
    padding: 0 8px 0;
  }
  object-fit: cover;
`;
export const SkipBox = styled.img`
  height: 36px;
  width: 32.91px;
  padding: 0 5px;
  @media (max-width: 767px) {
    height: 30px;
    width: 26.91px;
    padding: 0 4px;
  }
  object-fit: cover;
`;

export const NumberBox = styled.div`
  padding: 0 15px 0 15px;
  display: flex;
  @media (max-width: 767px) {
    padding: 0 8px 0 8px;
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0 50px 0;
`;