import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainTopicText from "modules/MainTopicText";
import axios from "axios";
import { FaSearch } from 'react-icons/fa'
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { SearchTag, SelectTag, ButtonSearch, ButtonA } from "./styled";
import { useTranslation } from "react-i18next";
import MediaQuery from 'react-responsive'
import { useHistory } from "react-router-dom";
import swal from 'sweetalert';


const MemberDirectory = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [TypeCompany, setTypeCompany] = useState([]);
  const [namesearch, setnamesearch] = useState('null');
  const [typesearch, settypesearch] = useState('null');

  useEffect(() => {
    const FetchProfile = async () => {
      axios
        .get(API_IP + "/Company/select", CONFIG)
        .then(function (response) {
          console.log('TypeCompany res',response)
          setTypeCompany(response.data);
        })
        .catch(function (error) {
        })

       
    };
    FetchProfile();
  }, []);
    const items = []
    const items2 = []
    var elements = []
    if(i18n.language == 'th'){
      elements = ['ก','ข','ฃ','ค','ฅ','ฆ','ง','จ','ฉ','ช','ซ','ฌ','ญ','ฎ','ฏ','ฐ','ฑ','ฒ'
      ,'ณ','ด','ต','ถ','ท','ธ','น','บ','ป','ผ','ฝ','พ','ฟ','ภ','ม','ย','ร','ล'
      ,'ว','ศ','ษ','ส','ห','ฬ','อ','ฮ'];
      console.log('TypeCompany',TypeCompany)
      for (const [index, value] of elements.entries()) {
        items.push(<MediaQuery maxDeviceWidth={1200}><Col md={2} sm={1} xs={3}><ButtonA role="button" href={"SearchMember/null/null/"+[value]+"/"+i18n.language} class="btn gz-alpha-btns" key={index}>{value}</ButtonA></Col>
        </MediaQuery>)
      }
      for (const [index, value] of elements.entries()) {
        items2.push(<MediaQuery minDeviceWidth={1200}><ButtonA role="button" href={"SearchMember/null/null/"+[value]+"/"+i18n.language} class="btn gz-alpha-btns" key={index}>{value}</ButtonA>
        </MediaQuery>)
      }
    }else if(i18n.language == 'en'){
      elements = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
      console.log('TypeCompany',TypeCompany)
      for (const [index, value] of elements.entries()) {
        items.push(<MediaQuery maxDeviceWidth={1200}><Col md={2} sm={1} xs={3}><ButtonA role="button" href={"SearchMember/null/null/"+[value]+"/"+i18n.language} class="btn gz-alpha-btns" key={index}>{value}</ButtonA></Col>
        </MediaQuery>)
      }
      for (const [index, value] of elements.entries()) {
        items2.push(<MediaQuery minDeviceWidth={1200}><ButtonA role="button" href={"SearchMember/null/null/"+[value]+"/"+i18n.language} class="btn gz-alpha-btns" key={index}>{value}</ButtonA>
        </MediaQuery>)
      }
    }else if(i18n.language == 'ch'){
      // elements = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z'];
    }
  

  
  
  function handleChange(e) {
      setnamesearch(e.target.value);
  }

  function handleChange2(e) {
    console.log('type id!!',e.target.value);
    settypesearch(e.target.value);
  }

  function buttonsearch(){
      if(typesearch !== "null" || namesearch !== "null"){
        history.push({ pathname: "/searchMember/"+typesearch+"/"+namesearch+"/null/null" })
      }else{
        swal("กรุณากรอกคำค้นหา หรือ เลือกประเภทธุรกิจ");
      }
  }

  return (
    <div>
    <NavB />
    <Container>
      
      <MainTopicText state={i18n.language} name={t('navbar.dropdown.member')} />
      <Row style={{height:'100%', justifyContent: 'center'}}>
        <Col md={4} sm={4} xs={8} style={{paddingRight: '2px', paddingLeft: '0px', marginBottom: '5px'}}>
          <SearchTag type="search" name="" id="input"  placeholder={t('MemberDirectory.placeholder')}  title="" onChange={handleChange} />
        </Col>
        <Col md={4} sm={4} xs={8} style={{paddingLeft: '0px', paddingRight: '2px', marginBottom: '5px'}}>
          <SelectTag name="" id="" onChange={handleChange2}>
            <option selected value="*">{t('MemberDirectory.type_company')}</option>
          {
          TypeCompany.map((item, index)=>{
            return(
            <option value={item.company_id}>{item.name_th}</option>
            )
          })
        }
          </SelectTag>
        </Col>
        <Col onClick={buttonsearch} md={1} sm={1} xs={8} style={{padding:'0px', marginBottom: '5px'}}>          
            <ButtonSearch type="submit"><FaSearch style={{color:'#020d5d'}}
                          />
            </ButtonSearch>
        </Col>
      </Row>
      <div style={{placeContent: 'center', backgroundColor:'#152d83', color:'#ffff', margin: '0px 49px', borderRadius:'0.5rem'}} class="btn-toolbar gz-btngroup-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            
        <div class="btn-group gz-alphanumeric-btn" role="group" aria-label="Select by alphanumeric">
               <Row>
                    {items}
                    {items2}
               </Row>
        </div>
      </div>

      <br/>
      <br/>
      <br/>

      <Row style={{marginLeft: '45px'}}>
        {
          TypeCompany.map((item, index)=>{
            var name_company = ''
            if(i18n.language == 'th'){
              name_company = item.name_th
            }else if(i18n.language == 'en'){
              name_company = item.name_en
            }else if(i18n.language == 'ch'){
              name_company = item.name_cn
            }
            return(
        <Col md={6} style={styles.dataCole}>
          <a style={styles.dataA} role="button" href={"SearchMember/"+item.company_id+"/null/null/null"} >{name_company}</a>
        </Col>
            )
          })
        }
        
      </Row>
      

      {/* <PaginationTab perPage={1} currentPage={1} data={members}/> */}
      
    </Container>
    <Footer />
    </div>
  );
};

const styles = {

  dataCole: {
    paddingBottom: '25px'
  },

  dataA: { 
    paddingBottom: '20px',
    color: '#152d83',
    fontFamily: 'promptLight',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  }

}

export default MemberDirectory;

