import React from "react";
import {
  WhiteBox,
  Box,
  LanBox,
  SocialBox,
  BigSocialBox,
  ChineseTopic,
  PicBox,
  ThaiTopic
} from "./styled";
import youtube from "./images/youtube.svg";
import line from "./images/line.svg";
import ig from "./images/ig.svg";
import facebook from "./images/facebook.svg";
import logo from "./images/logo.svg";
import { useTranslation } from "react-i18next";
const TopicNav = () => {
  const { i18n } = useTranslation();
  const ChangeLanguage = (language) => {
    i18n.changeLanguage(language)
  };

  return (
    <WhiteBox>
      <div style={{ display: 'flex' }}>
        <PicBox src={logo} alt="logo" />
        <Box style={{ marginLeft: "10px" }}>
          <ChineseTopic>泰国中华总商会</ChineseTopic>
          <ThaiTopic>THAI - CHINESE CHAMBER OF COMMERCE</ThaiTopic>
        </Box>
      </div>
      <div style={{ display: "flex" }}>
        <Box style={{ marginRight: "20px" }}>
          <div style={{ color: "#b9ab60", marginLeft: '48px'}}>
            SOCIAL MEDIA
          </div>
          <BigSocialBox>
            <a href="" target = "_blank">
              <SocialBox src={line}/>
            </a>
            <a href="https://www.youtube.com/channel/UC539YC1YmxExQ9xJAYNQzfQ" target = "_blank">
              <SocialBox style={{ width: "40px" }} src={youtube} />
            </a>
            <a href="" target = "_blank">
              <SocialBox src={ig} />
            </a>
            <a href="https://www.facebook.com/ThaiChineseChamberofCommerce/" target = "_blank">
              <SocialBox src={facebook} />
            </a>
          </BigSocialBox>
        </Box>
        <Box style={{ paddingTop: '0'}}>
          <LanBox style={{ marginBottom: "4px",backgroundColor:i18n.language=='th'?'rgba(253, 214, 0, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('th')}>TH</LanBox>
          <LanBox style={{ marginBottom: "4px" ,backgroundColor:i18n.language=='en'?'rgba(253, 214, 0, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('en')}>ENG</LanBox>
          <LanBox style={{backgroundColor:i18n.language=='ch'?'rgba(253, 214, 0, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('ch')}>中文</LanBox>
        </Box>
      </div>
    </WhiteBox>
  );
};

export default TopicNav;
