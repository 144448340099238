import styled from "styled-components";

export const TopicBox = styled.div`
  margin-bottom: 30px;
`;

export const PicBox = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const TextBox = styled.div`
  font-family: promptLight;
  text-align: center;
  color: #b9ab60;
  font-size: 30px;
  top: 15px;
  position: relative;
`;

export const TextBox2 = styled(TextBox)`
  font-family: cinzel;
  letter-spacing: 5px;
`;
