import React from "react";
import MainTopicText from "modules/MainTopicText";
import PaginationTab from "modules/PaginationTab";
import { Container, Row, Col } from "react-bootstrap";
import { TopicText, Line, DetailText } from "./styled";
import girl1 from "modules/images/girl1.jpg";
import girl2 from "modules/images/girl2.jpg";
import man1 from "modules/images/man1.jpg";
import man2 from "modules/images/man2.jpg";
import ModalMember from 'modules/ModalMember'
import { useTranslation } from "react-i18next";
export const HEADER_STATES = {
  HonorMember: 0,
};

const HeaderRender = ({ numHeader, name, data, data2, data3 }) => {
  const { t, i18n } = useTranslation();
  const renderHeader = () => {
    if (numHeader == 0) {
      return <MainTopicText state={i18n.language} name={name} />;
    }else if(numHeader == 1){
      return <MainTopicText state={i18n.language} name={t('searchBoardMember.topsearch')}/>;
    } else {
      return (
        <div style={{ margin: "50px 0" }}>
          {numHeader == "SearchBoardMember"?<TopicText>{t('searchBoardMember.title')}</TopicText>:<TopicText>{t('boardMember.header1')+' '+t('boardMember.header2')}</TopicText>}
          <Line />
          <DetailText state={i18n.language}>{name}</DetailText>
        </div>
      );
    }
  };
  if(data.length > 0){
    console.log('wowbozz',data);
    return (
      <Container>
        <div>{renderHeader()}</div>
        <Row md={4}>
        {
          
          data.map((item,index)=>{
            
            return(
              <Col key={index} xs={12}>
                <ModalMember 
                  surnameEng={item.name_en}
                  lastnameEng={item.lastname_en}
                  pic={item.url_img}
                  surnameThai={item.name_th}
                  lastnameThai={item.lastname_th}
                  surnameCn={item.name_cn}
                  lastnameCn={item.lastname_th}
                  nameChi={item.name_cn}
                  lastnameChi={item.lastname_cn}
                  positionChi={(item.position_tcc[0].position_cn ? item.position_tcc[0].position_cn : "ไม่พบข้อมูลcn")}
                  positionThai={(item.position_tcc[0].position_th ? item.position_tcc[0].position_th : "ไม่พบข้อมูลth")}
                  positionEng={(item.position_tcc[0].position_en ? item.position_tcc[0].position_en : "ไม่พบข้อมูลen")}
                  companyThai={item.company_th}
                  companyEng={item.company_en}
                  companyChi={item.company_cn}
                  company_group={item.company_group}
                  ComPositionThai={item.position_th}
                  ComPositionEng={item.position_en}
                  ComPositionChi={item.position_cn}
                  email={item.email}
                  phone={item.phone}
                  companyPhone={item.phone_company}
                  locationThai={item.location_th}
                  locationEng={item.location_en}
                  locationCn={item.location_cn}
                />
              </Col>
            )
            
          })
        }
        </Row>
        
        {/* <PaginationTab /> */}
      </Container>
    );
  }
   else if(data3.length > 0){
    console.log('data1',data);
    return (
      <Container>
        <div>{renderHeader()}</div>
        <Row md={4}>
        {
          
          data.map((item,index)=>{
            var departmentThai;
            var departmentEng;
            var departmentChi;
            var positiontcc_cn;
            var positiontcc_th;
            var positiontcc_en;

            if(item.department != ''){
              departmentThai=item.department[0].department_th;
              departmentEng=item.department[0].department_en;
              departmentChi=item.department[0].department_cn;
            }else{
              departmentThai='';
              departmentEng='';
              departmentChi='';
            }

            if(item.position_tcc != ''){
              positiontcc_th=item.position_tcc[0].position_th;
              positiontcc_en=item.position_tcc[0].position_en;
              positiontcc_cn=item.position_tcc[0].position_cn;
            }else{
              positiontcc_th='';
              positiontcc_en='';
              positiontcc_cn='';
            }
            return(
              <Col key={index} xs={12}>
                <ModalMember 
                  surnameEng={item.name_en}
                  lastnameEng={item.lastname_en}
                  pic={item.url_img}
                  surnameThai={item.name_th}
                  lastnameThai={item.lastname_th}
                  surnameCn={item.name_cn}
                  lastnameCn={item.lastname_th}
                  nameChi={item.name_cn}
                  lastnameChi={item.lastname_cn}
                  positionChi={positiontcc_cn}
                  positionThai={positiontcc_th}
                  positionEng={positiontcc_en}
                  departmentThai={departmentThai}
                  departmentEng={departmentEng}
                  departmentChi={departmentChi}
                  companyThai={item.company_th}
                  companyEng={item.company_en}
                  companyChi={item.company_cn}
                  company_group={item.company_group}
                  ComPositionThai={item.position_th}
                  ComPositionEng={item.position_en}
                  ComPositionChi={item.position_cn}
                  email={item.email}
                  phone={item.phone}
                  companyPhone={item.phone_company}
                  locationThai={item.location_th}
                  locationEng={item.location_en}
                  locationCn={item.location_cn}
                />
              </Col>
            )
            
          })
        }
        </Row>
        
        {/* <PaginationTab /> */}
      </Container>
    );
  }else if(data2.length > 0){
    console.log('data2',data2);

    return (
      <Container>
        <div>{renderHeader()}</div>
        <Row md={4}>
        {
          
          data2.map((item,index)=>{
            var departmentThai;
            var departmentEng;
            var departmentChi;
            var positiontcc_cn;
            var positiontcc_th;
            var positiontcc_en;

            if(item.department != ''){
              departmentThai=item.department[0].department_th;
              departmentEng=item.department[0].department_en;
              departmentChi=item.department[0].department_cn;
            }else{
              departmentThai='';
              departmentEng='';
              departmentChi='';
            }

            if(item.position_tcc != ''){
              positiontcc_th=item.position_tcc[0].position_th;
              positiontcc_en=item.position_tcc[0].position_en;
              positiontcc_cn=item.position_tcc[0].position_cn;
            }else{
              positiontcc_th='';
              positiontcc_en='';
              positiontcc_cn='';
            }
            return(
              <Col key={index} xs={12}>
                <ModalMember 
                  surnameEng={item.name_en}
                  lastnameEng={item.lastname_en}
                  pic={item.url_img}
                  surnameThai={item.name_th}
                  lastnameThai={item.lastname_th}
                  surnameCn={item.name_cn}
                  lastnameCn={item.lastname_th}
                  nameChi={item.name_cn}
                  lastnameChi={item.lastname_cn}
                  positionChi={positiontcc_cn}
                  positionThai={positiontcc_th}
                  positionEng={positiontcc_en}
                  departmentThai={departmentThai}
                  departmentEng={departmentEng}
                  departmentChi={departmentChi}
                  companyThai={item.company_th}
                  companyEng={item.company_en}
                  companyChi={item.company_cn}
                  company_group={item.company_group}
                  ComPositionThai={item.position_th}
                  ComPositionEng={item.position_en}
                  ComPositionChi={item.position_cn}
                  email={item.email}
                  phone={item.phone}
                  companyPhone={item.phone_company}
                  locationThai={item.location_th}
                  locationEng={item.location_en}
                  locationCn={item.location_cn}
                />
              </Col>
            )
            
          })
        }
        </Row>
        
        {/* <PaginationTab /> */}
      </Container>
    );
  }else{
    return (
      <Container>
        <div>{renderHeader()}</div>
        <div style={{textAlign:"center"}}><h4>ไม่พบข้อมูล</h4></div>
        <br></br>
        
        {/* <PaginationTab /> */}
      </Container>
    );
  }
  
};

export default HeaderRender;
