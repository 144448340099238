import React from "react";
import { Card, Detail, PicBox, StatusText } from "./styled";
import { useTranslation } from "react-i18next";

const MemberCard = ({ surnameEng, lastnameEng, status, pic, surnameThai, lastnameThai, nameChi, lastnameChi, position }) => {
  const { t, i18n } = useTranslation();
        var name = ''
        var lastname = ''
        var imange;
        var area;
      if( position !== "President" ){
        imange = styles.Public;
        area = styles.PublicArea;
        if(i18n.language == 'th'){
          name = surnameThai
          lastname = lastnameThai
        }else if(i18n.language == 'en'){
          name = surnameEng
          lastname = lastnameEng
        }else if(i18n.language == 'ch'){
          name = nameChi
          lastname = lastnameChi
        } 
      }else{
        imange = styles.President;
        area = styles.PresidentArea;
      }
        
  return (
    <Card>
      <div style={area}>
      <PicBox style={imange} src={pic} alt="member" />
      </div>
      <Detail>
        <div>
          <div style={{ textAlign: "center" }}>{name}</div>
          <div style={{ textAlign: "center" }}>{lastname}</div>
          <StatusText>{status}</StatusText>
        </div>
      </Detail>
    </Card>
  );
};

const styles = {

  President: {
    maxHeight: "500px", maxWidth: "300px"
  },

  PresidentArea: {
    height: "400px", textAlign: "center"
  },

  Public: { 
    maxHeight: "300px", maxWidth: "220px"
  },

  PublicArea: { 
    height: "300px", textAlign: "center"
  }

}

export default MemberCard;
