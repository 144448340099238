import React, { useState, useEffect } from "react";
import axios from "axios";
import { LandingBackground, LandingBanner, ButtonImage } from "./styled";
import ArtworkForWebsitRepeat from "modules/images/ArtworkForWebsitRepeat.jpg";
import BannerMockup from "modules/images/BannerMockup.jpg";
import ButtonEnterSize12 from "modules/images/ButtonEnterSize12.png";
import ButtonEnterSize13 from "modules/images/ButtonEnterSize13.png";
import { API_IP, CONFIG } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FsLightbox from 'fslightbox-react';
const LandingPage = () => {
  const history = useHistory();
  const { i18n } = useTranslation();
  const [landing, setlanding] = useState([]);
  const [toggler, setToggler] = useState();
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Reading", CONFIG)
      .then(function (response) {
        setlanding(response.data);
        setToggler(true);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);
  const EnterSide = (language) => {
    i18n.changeLanguage(language)
    history.push({ pathname: "/home" });
  };
  if(landing.length>0){
    return (
      <div>
         {/* <FsLightbox
                toggler={ toggler }
                sources={ [
                    'https://scontent.fbkk8-4.fna.fbcdn.net/v/t1.0-9/119229201_317530639701687_9146617723347411290_n.jpg?_nc_cat=100&_nc_sid=730e14&_nc_eui2=AeGyu3wgOYMO8CJo-D6s50fZ95706Uj5RUf3nvTpSPlFR-EIADI0VrMHW4T5QkB0TDnarq3_Pzfk-nGnDstwwpfT&_nc_ohc=fzvw8b5uS-4AX8p1nkV&_nc_ht=scontent.fbkk8-4.fna&oh=39916bea9ccbaa53b4939eeae3642655&oe=5F89D266',
                ] }
            /> */}
        <div style={{backgroundImage:`url(${landing[0].img_background})`}}>
          <LandingBackground className='row'>
            <LandingBanner src={landing[0].img_banner}/>
          
          </LandingBackground>
          <div className='row justify-content-center' style={{paddingBottom:'55px'}}>
            <div className="col-7 col-md-2" style={{padding:'5px 50px'}} onClick={()=>EnterSide('th')}>
            <ButtonImage src={landing[0].img_font_th}/>
            </div>
            <div className="col-7 col-md-2" style={{padding:'5px 50px'}} onClick={()=>EnterSide('en')}>
            <ButtonImage src={landing[0].img_font_en}/>
            </div>
            <div className="col-7 col-md-2" style={{padding:'5px 50px'}} onClick={()=>EnterSide('ch')}>
            <ButtonImage src={landing[0].img_font_cn}/>
            </div>
          </div>
        </div>
      </div>
    );
  }else{
    return (
      <div >
       
      </div>
    );
  }
  
};

export default LandingPage;
