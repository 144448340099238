import React, { useState, useEffect } from "react";
import background from "modules/images/background.jpg";
import head from "modules/images/head.png";
import {
  PicBox,
  HeadBox,
  Box,
  TextBox,
  Circle,
  PicNews,
  CarouselCustom,
  RowCustom,
} from "./styled";
import logo from "modules/TopicNav/images/logo.svg";
import DetailText from "modules/DetailText";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import MainTopicTextBlue from "modules/MainTopicBlue";
import NewsChamber from "modules/NewsChamber";
import Mediaquery from "react-responsive";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
const VisionmissionPage = () => {
  const [about, setabout] = useState([]);
  const [aboutbanner, setaboutbanner] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Mission_vision", CONFIG)
      .then(function (response) {
        setabout(response.data);
      })
      .catch(function (error) {
      })

      axios
      .get(API_IP + "/About/banner", CONFIG)
      .then(function (response) {
        setaboutbanner(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);

  var array_aboutbanner = []
  var item = []
  for(var i = 0 ; i< aboutbanner.length;i++){
    if(item.length < 7){
      item.push(aboutbanner[i])
    }else{
      item.push(aboutbanner[i])
      var data_temp = {
        data_array:item
      }
      array_aboutbanner.push(data_temp)
      item = []
    }
  }
  if(item.length > 0){
    var data_temp = {
      data_array:item
    }
    array_aboutbanner.push(data_temp)
  }
  const { t,i18n } = useTranslation();
  if(about.length > 0){
    var description_vision = ''
    var description_mission = ''

    if(i18n.language == 'en'){
      description_vision = about[0].description_vision_en
      description_mission = about[0].description_mission_en
    }else if(i18n.language == 'th'){
      description_vision = about[0].description_vision_th
      description_mission = about[0].description_mission_th
    }else if(i18n.language == 'ch'){
      description_vision = about[0].description_vision_cn
      description_mission = about[0].description_mission_cn
    }
  }
  return (
    <div>
      <NavB />
      <Box>
        <PicBox src={background} alt="background" />
        <TextBox>
          <Container>
            <div style={{ textAlign: "center" }}>
              <Circle src={logo} alt="logo" />
              <div style={{ fontWeight: "bold" }}>หอการค้าไทย - จีน</div>
              <div style={{ fontFamily: "cinzel" }}>
                THAI - CHINESE CHAMBER OF COMMERCE
              </div>
            </div>
          </Container>
        </TextBox>
      </Box>
      <HeadBox src={head} alt="headBackground" />
      <Container style={{marginTop:-120}}>
        <MainTopicTextBlue state={i18n.language} name={t('home.vision_mission')} />
      </Container>
      <Container>
        <DetailText 
          state={i18n.language}
          name={t('vision_mission.vision')}
        />
        {about.length > 0 &&<Row>
          <Col dangerouslySetInnerHTML={{__html: description_vision}}/>
        </Row>}
      </Container>
      <Container>
        <DetailText 
          state={i18n.language}
          name={t('vision_mission.mission')}
        />
        {about.length > 0 &&<Row>
          <Col dangerouslySetInnerHTML={{__html: description_mission}}/>
        </Row>}
      </Container>
      {/* <Container>
        <DetailText
          state={'en'}
          name={t('about.chamber_of_commerce_network')}
        />
      </Container>

      <Mediaquery minWidth={768}>
        <Container>
          <CarouselCustom>
            {
              array_aboutbanner.map((item, index)=>{
                var item1 = item.data_array
                return(
                  <Carousel.Item key={index}>
                    <RowCustom md={4}>
                      {
                        item1.map((item2, index)=>{
                          return(
                            <Col xs={12}>
                              <NewsChamber data={item2}/>
                            </Col>
                          )
                        })
                      }
                    </RowCustom>
                  </Carousel.Item>
                )
              })
            }
            
            
          </CarouselCustom>
          
        </Container>
      </Mediaquery> */}
      <Footer />
    </div>
  );
};

export default VisionmissionPage;
