import React, { useState, useEffect } from "react";
import SecondTopicText from "modules/SecondTopicText";
import { Container, Row, Col, Form } from "react-bootstrap";
import {
  PicBox,
  HeadBox,
  TextBox,
  Box,
  HeaderBox,
  HeadPicBox,
  FormCustom,
} from "./styled";
import background from "modules/images/background.jpg";
import head from "modules/images/head.png";
import FooterMember from "modules/FooterMember";
import ModalMember from "modules/ModalMember";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import { FaSearch } from 'react-icons/fa'
import { useHistory } from "react-router-dom";
const BoardMember = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [broadMembers, setBroadMembers] = useState([]);
  const [broadMembersAll, setBroadMembersAll] = useState([]);
  const [Position, setPosition] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [namesearch, setnamesearch] = useState('null');
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Profile", CONFIG)
      .then(function (response) {
        setBroadMembers(response.data);
        setBroadMembersAll(response.data);
        console.log('kkkkkkkkk', response.data);
      })
      .catch(function (error) {
      })

     
    };
    
    fetchData();
  }, []);

  function handleChange(e) {
    console.log(e.target.value);
    if(e.target.value == ''){
      setBroadMembers(broadMembersAll);
    }else{
      var array = []
      for(var i = 0; i < broadMembersAll.length ; i++){
        var name = ''
        var lastname = ''
        if(i18n.language == 'th'){
          name = broadMembersAll[i].name_th
          lastname = broadMembersAll[i].lastname_th
        }else if(i18n.language == 'en'){
          name = broadMembersAll[i].name_en
          lastname = broadMembersAll[i].lastname_en
        }else if(i18n.language == 'ch'){
          name = broadMembersAll[i].name_cn
          lastname = broadMembersAll[i].lastname_cn
        }
        
        if(name.indexOf(e.target.value) != -1 || lastname.indexOf(e.target.value) != -1){
          array.push(broadMembersAll[i])
        }
      }
      setBroadMembers(array);
    }
    
  }
  function onSearch(e){
    setnamesearch(e.target.value)
  }

  function onSearchGo(){
    history.push({ pathname: `/searchtop/${namesearch}` });
  }
  return (
    <div>
      <NavB />
      <div style={{ marginBottom: "50px" }}>
        <HeaderBox>
          <div>{t('boardMember.header2')}</div>
          <div>{t('boardMember.header1')}</div>
          <div style={{textAlign:'-webkit-center'}}>
          <Col md={6} xs={12}>
            <FormCustom>
              <div style={{paddingLeft:'5px',height:'100%',display:'flex',alignItems:'center'}}>
                <input name="" onChange={onSearch} style={{width:'90%',border:'none',borderRadius:20,paddingLeft:'10px',outline:'none'}}/>
                <FaSearch type='button' onClick={onSearchGo} color="#0a2980" size="1vw" style={{width:'10%'}}/>
              </div>

            </FormCustom>
          </Col>
          </div>
         
        </HeaderBox>

        {broadMembers.length > 0 && broadMembers
          .filter(
            (broadMember) => broadMember.position_tcc != "" && broadMember.position_tcc[0].position_en == "President"
          )
          .map((President, index) => (
            <div>
            <Col key={index} xs={12}>
                 <ModalMember
                  surnameEng={President.name_en}
                  lastnameEng={President.lastname_en}
                  pic={President.url_img}
                  surnameThai={President.name_th}
                  lastnameThai={President.lastname_th}
                  surnameCn={President.name_cn}
                  lastnameCn={President.lastname_th}
                  nameChi={President.name_cn}
                  lastnameChi={President.lastname_cn}
                  positionChi={President.position_tcc[0].position_cn}
                  positionThai={President.position_tcc[0].position_th}
                  positionEng={President.position_tcc[0].position_en}
                  companyThai={President.company_th}
                  companyEng={President.company_en}
                  companyChi={President.company_cn}
                  company_group={President.company_group}
                  ComPositionThai={President.position_th}
                  ComPositionEng={President.position_en}
                  ComPositionChi={President.position_cn}
                  email={President.email}
                  phone={President.phone}
                  companyPhone={President.phone_company}
                  locationThai={President.location_th}
                  locationEng={President.location_en}
                  locationCn={President.location_cn}
                />
              </Col>
              <HeaderBox>
                <div style={{ fontFamily: "promptLight" }}>
                  {President.name_th} {President.lastname_th}
                </div>
                <div style={{ fontSize: "20px" }}>
                  {President.name_en} {President.lastname_en}
                </div>
                <div style={{ letterSpacing: "10px" }}>
                  {President.position_tcc[0].position_en}
                </div>
                <div style={{ letterSpacing: "10px", fontFamily: "promptLight" }}>
                  {President.position_tcc[0].position_th}
                </div>
              </HeaderBox>
            </div>
          ))}
      </div>
      
      <Container>
        <SecondTopicText state={i18n.language} name={t('boardMember.vice_president')} />
        <Row md={4}>
          {broadMembers.length > 0 && broadMembers
            .filter(
              (broadMember) =>
              broadMember.position_tcc != "" && broadMember.position_tcc[0].position_en == "Vice President"
            )
            .map((vicePresident, index) => (
              <Col key={index} xs={12}>
                <ModalMember
                  surnameEng={vicePresident.name_en}
                  lastnameEng={vicePresident.lastname_en}
                  pic={vicePresident.url_img}
                  surnameThai={vicePresident.name_th}
                  lastnameThai={vicePresident.lastname_th}
                  surnameCn={vicePresident.name_cn}
                  lastnameCn={vicePresident.lastname_th}
                  nameChi={vicePresident.name_cn}
                  lastnameChi={vicePresident.lastname_cn}
                  positionChi={vicePresident.position_tcc[0].position_cn}
                  positionThai={vicePresident.position_tcc[0].position_th}
                  positionEng={vicePresident.position_tcc[0].position_en}
                  companyThai={vicePresident.company_th}
                  companyEng={vicePresident.company_en}
                  companyChi={vicePresident.company_cn}
                  company_group={vicePresident.company_group}
                  ComPositionThai={vicePresident.position_th}
                  ComPositionEng={vicePresident.position_en}
                  ComPositionChi={vicePresident.position_cn}
                  email={vicePresident.email}
                  phone={vicePresident.phone}
                  companyPhone={vicePresident.phone_company}
                  locationThai={vicePresident.location_th}
                  locationEng={vicePresident.location_en}
                />
              </Col>
            ))}
        </Row>
      </Container>
      <Container>
        <SecondTopicText
          state={i18n.language}
          name={t('boardMember.secretary_general')+t('boardMember.and')}
          SecondText={t('boardMember.deputy_secretary_general')}
        />
        <Row md={4} style={{ placeContent: "center"}}>
          {broadMembers.length > 0 && broadMembers
            .filter((broadMember) =>
            broadMember.position_tcc != "" && broadMember.position_tcc[0].position_en == "Secretary General"
            )
            .map((SecretaryGeneral, index) => (
              <Col key={index} xs={12}>
                <ModalMember
                  surnameEng={SecretaryGeneral.name_en}
                  lastnameEng={SecretaryGeneral.lastname_en}
                  pic={SecretaryGeneral.url_img}
                  surnameThai={SecretaryGeneral.name_th}
                  lastnameThai={SecretaryGeneral.lastname_th}
                  nameChi={SecretaryGeneral.name_cn}
                  positionChi={SecretaryGeneral.position_tcc[0].position_cn}
                  positionThai={SecretaryGeneral.position_tcc[0].position_th}
                  positionEng={SecretaryGeneral.position_tcc[0].position_en}
                  companyThai={SecretaryGeneral.company_th}
                  companyEng={SecretaryGeneral.company_en}
                  companyChi={SecretaryGeneral.company_cn}
                  company_group={SecretaryGeneral.company_group}
                  ComPositionThai={SecretaryGeneral.position_th}
                  ComPositionEng={SecretaryGeneral.position_en}
                  ComPositionChi={SecretaryGeneral.position_cn}
                  email={SecretaryGeneral.email}
                  phone={SecretaryGeneral.phone}
                  companyPhone={SecretaryGeneral.phone_company}
                  locationThai={SecretaryGeneral.location_th}
                  locationEng={SecretaryGeneral.location_en}
                />
              </Col>
            ))}
        </Row>
        <Row md={4}>
          {broadMembers.length > 0 && broadMembers
            .filter((broadMember) =>
            broadMember.position_tcc != "" && broadMember.position_tcc[0].position_en == "Deputy Secretary General"
            )
            .map((SecretaryGeneral, index) => (
              <Col key={index} xs={12}>
                <ModalMember
                  surnameEng={SecretaryGeneral.name_en}
                  lastnameEng={SecretaryGeneral.lastname_en}
                  pic={SecretaryGeneral.url_img}
                  surnameThai={SecretaryGeneral.name_th}
                  lastnameThai={SecretaryGeneral.lastname_th}
                  nameChi={SecretaryGeneral.name_cn}
                  positionChi={SecretaryGeneral.position_tcc[0].position_cn}
                  positionThai={SecretaryGeneral.position_tcc[0].position_th}
                  positionEng={SecretaryGeneral.position_tcc[0].position_en}
                  companyThai={SecretaryGeneral.company_th}
                  companyEng={SecretaryGeneral.company_en}
                  companyChi={SecretaryGeneral.company_cn}
                  company_group={SecretaryGeneral.company_group}
                  ComPositionThai={SecretaryGeneral.position_th}
                  ComPositionEng={SecretaryGeneral.position_en}
                  ComPositionChi={SecretaryGeneral.position_cn}
                  email={SecretaryGeneral.email}
                  phone={SecretaryGeneral.phone}
                  companyPhone={SecretaryGeneral.phone_company}
                  locationThai={SecretaryGeneral.location_th}
                  locationEng={SecretaryGeneral.location_en}
                />
              </Col>
            ))}
        </Row>
      </Container>
      <Container>
        <SecondTopicText
          state={i18n.language}
          name={t('boardMember.assistant_secretary_general')}
        />
        <Row md={4}>
          {broadMembers.length > 0 && broadMembers
            .filter((broadMember) =>
            broadMember.position_tcc != "" &&  broadMember.position_tcc[0].position_en == "Assistant Secretary General"
            )
            .map((SecretaryGeneral, index) => (
              <Col key={index} xs={12}>
                <ModalMember
                  surnameEng={SecretaryGeneral.name_en}
                  lastnameEng={SecretaryGeneral.lastname_en}
                  pic={SecretaryGeneral.url_img}
                  surnameThai={SecretaryGeneral.name_th}
                  lastnameThai={SecretaryGeneral.lastname_th}
                  nameChi={SecretaryGeneral.name_cn}
                  positionChi={SecretaryGeneral.position_tcc[0].position_cn}
                  positionThai={SecretaryGeneral.position_tcc[0].position_th}
                  positionEng={SecretaryGeneral.position_tcc[0].position_en}
                  companyThai={SecretaryGeneral.company_th}
                  companyEng={SecretaryGeneral.company_en}
                  companyChi={SecretaryGeneral.company_cn}
                  company_group={SecretaryGeneral.company_group}
                  ComPositionThai={SecretaryGeneral.position_th}
                  ComPositionEng={SecretaryGeneral.position_en}
                  ComPositionChi={SecretaryGeneral.position_cn}
                  email={SecretaryGeneral.email}
                  phone={SecretaryGeneral.phone}
                  companyPhone={SecretaryGeneral.phone_company}
                  locationThai={SecretaryGeneral.location_th}
                  locationEng={SecretaryGeneral.location_en}
                />
              </Col>
            ))}
        </Row>
      </Container>
      <HeadBox src={head} alt="head" />
      <Box>
        <TextBox>
          <FooterMember />
        </TextBox>
        <PicBox src={background} alt="background" />
      </Box>
      <Footer />
    </div>
  );
};

export default BoardMember;
