import React from "react";
import HeaderRender from "modules/HeaderRender";
import { useParams } from "react-router-dom";
import NavB from "modules/NavB";
import Footer from "modules/Footer";

const MemberLibrary = () => {
  const { name } = useParams();
  return (
    <div>
      <NavB />
        <HeaderRender name={name} />
      <Footer />
    </div>
  );
};

export default MemberLibrary;
