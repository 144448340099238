import styled from "styled-components";
import { Carousel, Row } from "react-bootstrap";

export const RowCustom = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
  @media (max-width: 767px) {
    padding: 0 10vw;
  }
`;
