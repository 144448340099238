import React from "react";
import {
  Card,
  PicBox,
  BodyBox,
  Topic,
  LongLine,
  ShortLine,
  UnderLineBox,
} from "./styled";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const NewsCardHomePage = ({ data }) => {
  const history = useHistory();
  const OnClickDetailNews = () => {
    history.push({ pathname: "/DetailNews" });
  };
  const { i18n } = useTranslation();
  var title = ''
  var short_description = ''
  if(i18n.language == 'en'){
    title = data.title_en
    short_description = data.short_description_en
  }else if(i18n.language == 'th'){
    title = data.title_th
    short_description = data.short_description_th
  }else if(i18n.language == 'ch'){
    title = data.title_cn
    short_description = data.short_description_cn
  }
  return (
    <Card style={{height:'400px'}} onClick={OnClickDetailNews}>
      <PicBox style={{objectFit:'cover'}} src={data.img} />
      <Topic>
        <div>{title}</div>
        <UnderLineBox>
          <LongLine />
          <ShortLine />
        </UnderLineBox>
        
      </Topic>
      <BodyBox>{short_description}</BodyBox>
    </Card>
  );
};

export default NewsCardHomePage;
