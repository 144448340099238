import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import MainTopicText from "modules/MainTopicText";
import ButtonPush from "modules/ButtonPush";
import axios from "axios";
import { FaSearch } from 'react-icons/fa'
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { SearchTag, SelectTag, ButtonSearch, BottonTabAn } from "./styled";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MediaQuery from 'react-responsive'
import { MdPhoneInTalk } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { IoIosGlobe } from "react-icons/io";
import { useHistory } from "react-router-dom";

const InfoCompany = (props) => {
    
  const { t, i18n } = useTranslation();
  const [TypeCompany, setTypeCompany] = useState([]);
  const [NameCompany, setNameCompany] = useState([]);
  const info = props.match.params.info
  // const type = props.match.params.type
  // const nametype = props.match.params.nametype
  let history = useHistory();
    const goToPreviousPath = () => {
        history.goBack()
  }
  useEffect(() => {
    const TypeCompany = async () => {
      axios
        .get(API_IP + "/Company/select", CONFIG)
        .then(function (response) {
          console.log('TypeCompany res',response.data)
          setTypeCompany(response.data);
        })
        .catch(function (error) {
        })

       
    };

    const FetchProfile = async () => {
        axios
          .get(API_IP + `/Company/search_profile?id=${info}`, CONFIG)
          .then(function (response) {
            console.log('NameCompany res',response.data)
            setNameCompany(response.data);
          })
          .catch(function (error) {
          })
         
      };
    TypeCompany();
    FetchProfile();
  }, []);
  return (
    <div>
    <NavB />
    <Container>
      
      <MainTopicText state={i18n.language} name={t('navbar.dropdown.member')} />
      {/* <Row style={{height:'100%', justifyContent: 'center'}}>
        <Col md={4} sm={4} xs={8} style={{paddingRight: '2px', paddingLeft: '0px', marginBottom: '5px'}}>
          <SearchTag type="search" name="" id="input"  placeholder="คำค้นหา"  title=""/>
        </Col>
        <Col md={4} sm={4} xs={8} style={{paddingLeft: '0px', paddingRight: '2px', marginBottom: '5px'}}>
          <SelectTag name="" id="">
            <option selected value="">ประเภทธุรกิจ</option>
          {
          TypeCompany.map((item, index)=>{
            return(
            <option value="">{item.name_th}</option>
            )
          })
        }
          </SelectTag>
        </Col>
        <Col md={1} sm={1} xs={8} style={{padding:'0px', marginBottom: '5px'}}>
            <ButtonSearch type="submit"><FaSearch style={{color:'#020d5d'}}/></ButtonSearch>
        </Col>
      </Row> */}

      <br/>
        {
            NameCompany.map((item, index)=>{
                if(item.profile == info){
                  var nameCompany = [];
                  var companytype = item.company;
                  for (const [index, value] of companytype.entries()) {
                      nameCompany.push(<div><Row><Col md={1} xs={12} style={{ marginRight: "40px"}}></Col><Col md={8} xs={12}><h2>{value.name_th}</h2><h3>{value.name_en}</h3><h4>{value.name_cn}</h4></Col></Row><br/></div>)
                  }

                return(
                    <div style={{fontFamily: 'promptLight', color: '#152d83'}}>
                        <Row>
                            <Col md={1} xs={12}></Col>
                            <Col md={3} xs={12} style={{    textAlign: "center", padding:'0px 48px',height:'200px',display: "flex", alignItems: "center"}}>
                              <div style={{    width: "100%"}}>
                                <img style={{width:'100%',maxHeight:'175px',maxWidth:'175px'}} src={item.img_company}/>
                              </div>
                            </Col>
                            <Col md={8} xs={12} style={{ paddingTop: '30px',paddingLeft: '6px'}}>
                                <h2>{item.company_th}</h2>
                                <h3>{item.company_en}</h3>
                                <h4>{item.company_cn}</h4>
                            </Col>
                        </Row>
                        <br/>
                        <br/>
                        <Row>
                            <Col md={1} xs={12} style={{ marginRight: "50px"}}></Col>
                            <Col md={2} xs={12}
                                 style={{color:"white", 
                                         backgroundColor:"#152d83",
                                         textAlign: "center",
                                         borderRadius:"0.5rem",
                                         height:"40px",
                                         padding:'0px 40px',
                                         paddingTop:'3px'}}><h3>PROFILE</h3></Col>
                            <Col  xs={12} style={{flex: '0 0 8.333333%',maxWidth: '1.333333%'}}></Col>
                            <Col md={7} xs={12} style={{width:"0px",height:"0px", padding:"1px",margin:"19px 0px 0px 0px", backgroundColor: '#152d83'}}></Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col md={1} xs={12} style={{ marginRight: "40px"}}></Col>
                            <MediaQuery minDeviceWidth={1200}>
                                <Col md={6} xs={12} style={{borderRight: "solid",borderColor: "#152d83",borderWidth: "2px"}}>
                                    <h2>{item.name_th}  {item.lastname_th}</h2>
                                    <h3>{item.name_en}  {item.lastname_en}</h3>
                                    <h4>{item.name_cn}  {item.lastname_cn}</h4>
                                </Col>
                            </MediaQuery>
                            <MediaQuery maxDeviceWidth={1200}>
                                <Col md={6} xs={12} style={{marginBottom:"20px"}}>
                                    <h2>{item.name_th}  {item.lastname_th}</h2>
                                    <h3>{item.name_en}  {item.lastname_en}</h3>
                                    <h4>{item.name_cn}  {item.lastname_cn}</h4>
                                </Col>
                            </MediaQuery>
                            <Col md={3}>
                                <h2>{item.position_th}</h2>
                                <h3>{item.position_en}</h3>
                                <h4>{item.position_cn}</h4>
                            </Col>
                        </Row>
                        <br/>
                        {nameCompany}
                        <br/>
                        <Row>
                            <Col md={1} xs={12} style={{ marginRight: "40px"}}></Col>
                            <Col md={10} xs={12}>
                                <h5>{item.location_th}</h5>
                                <h5>{item.location_en}</h5>
                                <h5>{item.location_cn}</h5>
                            </Col>
                        </Row>
                        <br/>
                        <Row style={{alignItems: "center"}}>
                            <Col md={1} xs={12} style={{ marginRight: "40px"}}></Col>
                            <Col md={3} xs={12} style={{marginBottom:"10px"}}>
                                <Row style={{paddingBottom:"5px;"}}>
                                    <Col md={3} xs={2} style={{paddingRight:"0px"}}>
                                        <MdPhoneInTalk style={{padding: "5px",height: "35px", width:"35px",backgroundColor:"#152d83", color:"white",borderRadius:"2rem"}}/>
                                    </Col>
                                    <Col xs={5} style={{paddingLeft:"1px"}}>
                                        <a style={{color: "#152d83"}} href={"tel:"+item.phone_company}><h6 style={{paddingTop:"6px"}}>{item.phone_company}</h6></a>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3} xs={12} style={{marginBottom:"10px"}}>
                                <Row>
                                    <Col md={3} xs={2} style={{paddingRight:"0px"}}>
                                        <GoMail style={{padding: "5px",height: "35px", width:"35px",backgroundColor:"#152d83", color:"white",borderRadius:"2rem"}}/>
                                    </Col>
                                    <Col xs={5} style={{paddingLeft:"1px"}}>
                                        <h6 style={{paddingTop:"6px"}}>{item.email}</h6>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3} xs={12} style={{marginBottom:"10px"}}>
                                <Row>
                                    <Col md={3} xs={2} style={{paddingRight:"0px"}}>
                                        <IoIosGlobe style={{padding: "5px",height: "35px", width:"35px",backgroundColor:"#152d83", color:"white",borderRadius:"2rem"}}/>
                                    </Col>
                                    <Col xs={5} style={{paddingLeft:"1px"}}>
                                      <a style={{color: "#152d83"}} target="_blank" href={'http://'+item.website}><h6 style={{paddingTop:"6px"}}>{item.website}</h6></a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    )
                }
            })
        }
      <br/>
      <br/>

      <BottonTabAn onClick={goToPreviousPath} >
          {/* <Link to={{pathname: "/searchMember/"+info+"/"+nametype}}> */}
        <ButtonPush  name={t('MemberDirectory.goBack')}/>
        {/* <button name="อ่าว" onClick={goToPreviousPath}/> */}
        {/* </Link> */}
      </BottonTabAn>
      <br/>
      {/* {GoBack} */}
      

      {/* <PaginationTab perPage={1} currentPage={1} data={members}/> */}
      
    </Container>
    <Footer />
    </div>
  );
};

const styles = {

  dataCole: {
    paddingBottom: '25px'
  },

  dataA: { 
    paddingBottom: '20px',
    color: '#152d83',
    fontFamily: 'promptLight',
    fontSize: '1.2rem',
    fontWeight: 'bold'
  }

}

export default InfoCompany;

