export const API_IP = 'https://www.thaiccdev.club/api/ApiArea'
export const CONFIG = {
    headers: {
      "content-type": "application/json"
    },
    timeout: 10000
}
export const month_th = ["ม.ค.","ก.พ.","มี.ค","เม.ย.","พ.ค","มิ.ย.","ก.ค","ส.ค.","ก.ย.","ต.ค.","พ.ย.","ธ.ค."]
export const month_en = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"]
export const month_cn = ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"]
export const day_th = ["อ.","จ.","อ.","พ.","พฤ.","ศ.","ส.","อ."]
export const day_en = ["SUN","MON","TUE","WED","THU","FRI","SAT","SUN"]