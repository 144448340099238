import React from "react";
import Routes from "./route";

const App = () => {
  return (
    <Routes />
  );
};

export default App;
