import React, { useState, useEffect } from "react";
import { Box, TextBox, TopicText, LinkCustom, ModalCustom } from "./styled";
import ButtonPush from "modules/ButtonPush";
import PositionTab from "modules/PositionTab";
import DepartmentTab from "modules/DepartmentTab";
import { CloseBut } from "modules/ModalMember/styled";
import ButtonMember from "modules/ButtonMember";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const FooterMember = ({ name }) => {
  const { t,i18n } = useTranslation();
  const history = useHistory();
  const [modalPositionShow, setmodalPositionShow] = useState(false);
  const [modalDepartmentShow, setmodalDepartmentShow] = useState(false);
  const [positionState, setpositionState] = useState("กรุณาเลือกตำแหน่ง");
  const [departmentState, setdepartmentState] = useState("กรุณาเลือกฝ่าย");
  const [Position, setPosition] = useState([]);
  const [PositionId, setPositionId] = useState('');
  const [Department, setDepartment] = useState([]);
  const [DepartmentId, setDepartmentId] = useState('');
  const [modalShow, setModalShow] = React.useState(false);
  const [ErrorText, setErrorText] = React.useState('');
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Board/Position", CONFIG)
      .then(function (response) {
        var po = response.data
        var filter = po.filter((Position_data) => Position_data.menu == "ทำเนียบคณะกรรมการ")
        setPosition(filter)
        console.log('Position!!!!!!!',response.data);
      })
      .catch(function (error) {
      })
      axios
      .get(API_IP + `/Board/department`, CONFIG)
      .then(function (response) {
        setDepartment(response.data)
        console.log('Department!!!!!!!',response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);
  const onSearch = () => {
    console.log('onSearch data',PositionId, DepartmentId)
    var textId = ''
    var text = ''
    // if(PositionId == '' && DepartmentId == ''){
    //   setModalShow(true)
    //   setErrorText(t('boardMember.error_search_invalid'))
    // }else if(PositionId == '' && DepartmentId != ''){
    //   textId = `NA_${DepartmentId}`
    //   text = `NA_${departmentState}`
    //   history.push({ pathname: `/search/${text}/${textId}` });
    // }else if(PositionId != '' && DepartmentId == ''){
    //   textId = `${PositionId}_NA`
    //   text = `${positionState}_NA`
    //   history.push({ pathname: `/search/${text}/${textId}` });
    // }else if(PositionId != '' && DepartmentId != ''){
    //   textId = `${PositionId}_${DepartmentId}`
    //   text = `${positionState}_${departmentState}`
    //   history.push({ pathname: `/search/${text}/${textId}` });
    // }

    if(PositionId == '' && DepartmentId == ''){
      setModalShow(true)
      setErrorText(t('boardMember.error_search_invalid'))
    }else if(PositionId == '' && DepartmentId != ''){
      textId = `NA_${departmentState}`
      text = `NA_${departmentState}`
      history.push({ pathname: `/search/${text}/${textId}` });
    }else if(PositionId != '' && DepartmentId == ''){
      textId = `${positionState}_NA`
      text = `${positionState}_NA`
      history.push({ pathname: `/search/${text}/${textId}` });
    }else if(PositionId != '' && DepartmentId != ''){
      textId = `${positionState}_${departmentState}`
      text = `${positionState}_${departmentState}`
      history.push({ pathname: `/search/${text}/${textId}` });
    }
    
    
  }
  function ErrorModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('boardMember.error_search_title')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {ErrorText}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  const MyPositionModal = (props) => {
    
    return (
      <ModalCustom
        closeButton
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CloseBut closeButton>
          <div>
            {
              Position.map((item, index)=>{
                var position_name = ''
                if(i18n.language == 'th'){
                  position_name = item.position_th
                }else if(i18n.language == 'en'){
                  position_name = item.position_en
                }
                else if(i18n.language == 'ch'){
                  position_name = item.position_cn
                }
                return(
                  <div onClick={()=> {
                    setpositionState(position_name)
                    setPositionId(item.board_id)
                    setmodalPositionShow(false)
                    
                    }}
                  >{position_name}</div>
                )
              })
            }
          </div>
        </CloseBut>
      </ModalCustom>
    );
  };
  const MyDepartmentModal = (props) => {
    return (
      <ModalCustom
        closeButton
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <CloseBut closeButton>
          <div>
            {
              Department.map((item, index) => {
                var department_name = ''
                if(i18n.language == 'th'){
                  department_name = item.position_th
                }else if(i18n.language == 'en'){
                  department_name = item.position_en
                }
                else if(i18n.language == 'ch'){
                  department_name = item.position_cn
                }
                return(
                  <div onClick={()=> {
                    setdepartmentState(department_name)
                    setDepartmentId(item.department_id)
                    setmodalDepartmentShow(false)
                    }}
                  >{department_name}</div>
                )
              })
            }
            
            
          </div>
        </CloseBut>
      </ModalCustom>
    );
  };
  return (
    <Box>
      <ErrorModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div>
        <LinkCustom to={`/MemberLibrary/Senior Executive Directors`}>
          Senior Executive Directors
        </LinkCustom>
      </div>
      <div>
        <LinkCustom to={`/MemberLibrary/Directors`}>Directors</LinkCustom>
      </div>
      <TextBox>
        <TopicText>ค้นหาสมาชิก</TopicText>
      </TextBox>

     
      
      
       {/*  dropdown position */}
      <MyPositionModal
        show={modalPositionShow}
        onHide={() => setmodalPositionShow(false)}
      />
      <div onClick={() => setmodalPositionShow(true)}>
        <ButtonMember name={positionState} />
      </div>

      {/*  dropdown department */}
      <MyDepartmentModal
        show={modalDepartmentShow}
        onHide={() => setmodalDepartmentShow(false)}
      />
      <div onClick={() => setmodalDepartmentShow(true)}>
        <ButtonMember name={departmentState} />
      </div>
      <div onClick={()=>onSearch()}>
        <ButtonPush name="ค้นหา" />
      </div>
    </Box>
  );
};

export default FooterMember;
