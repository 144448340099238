import React from "react";
import {
  Box,
  TextBox,
  LineBox,
  RedLine,
  BlueLine,
  StatusBox,
  BuildBox,
  BuildBox2,
  DetailBox,
  PicBox,
  InfoBox,
  CommuBox,
} from "./styled";
import { Container, Row, Col } from "react-bootstrap";
import phoneU from "modules/images/phone.svg";
import fax from "modules/images/fax.svg";
import mail from "modules/images/mail.svg";
import Mediaquery from "react-responsive";
import { useTranslation } from "react-i18next";

export const PAGE_STATE = {
  memberTab: 0,
  modal: 1,
};

const MemberDetailCard = ({
  PageState,
  surnameThai,
  lastnameThai,
  surnameEng,
  lastnameEng,
  nameChi,
  lastnameChi,
  phone,
  companyPhone,
  email,
  companyThai,
  companyEng,
  companyChi,
  company_group,
  ComPositionThai,
  ComPositionEng,
  ComPositionChi,
  locationThai,
  locationEng,
  locationCn
}) => {
  var name_company_th = [];
  var name_company_en = [];
  var name_company_cn = [];
  // console.log(company_group);
  const { t, i18n } = useTranslation();
    
      {company_group.map((company_group, index) => {
        name_company_th[index] = company_group.company_th
        name_company_en[index] = company_group.company_en
        name_company_cn[index] = company_group.company_cn
      })}
    
  const renderHeader = () => {
    return (
      <div>
        <TextBox>
          <div>{nameChi} {lastnameChi}</div>
          <div style={{ fontFamily: "promptLight" }}>
            {surnameThai} {lastnameThai}
          </div>
          <div style={{ fontFamily: "cinzel" }}>
            {surnameEng} {lastnameEng}
          </div>
        </TextBox>
        <LineBox>
          <RedLine />
          <BlueLine />
        </LineBox>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div>
        <Mediaquery minWidth={992}>
          <CommuBox>
            <InfoBox>
              <PicBox src={phoneU} alt="phone" />
              <div>{phone}</div>
            </InfoBox>
            <InfoBox>
              <PicBox src={fax} alt="phone" />
              <div>{companyPhone}</div>
            </InfoBox>
            <InfoBox>
              <PicBox src={mail} alt="phone" />
              <div>{email}</div>
            </InfoBox>
          </CommuBox>
        </Mediaquery>

        <Mediaquery maxWidth={991}>
          <StatusBox>
            <InfoBox>
              <PicBox src={phoneU} alt="phone" />
              <div>{phone}</div>
            </InfoBox>
            <InfoBox>
              <PicBox src={fax} alt="phone" />
              <div>{companyPhone}</div>
            </InfoBox>
            <InfoBox>
              <PicBox src={mail} alt="phone" />
              <div>{email}</div>
            </InfoBox>
          </StatusBox>
        </Mediaquery>
      </div>
    );
  };
  
  const renderPage = () => {
    return (
      <div>
        {PageState ? null : <div>{renderHeader()}</div>}

        <Mediaquery minWidth={992}>
          <StatusBox>
            <BuildBox>
              <div>{companyThai}</div>
              <div>{companyEng}</div>
              <div>{companyChi}</div>
            </BuildBox>
            <BuildBox2>
              <div>{ComPositionThai}</div>
              <div>{ComPositionEng}</div>
              <div>{ComPositionChi}</div>
            </BuildBox2>
          </StatusBox>
          <DetailBox>
              <div style={{paddingLeft: "15px"}}>
                ประเภทธุรกิจ : {company_group.map((company_group, index) => { 
                return <div md={12}>
                      <Col md={12} key={index}>{company_group.company_th}</Col>
                      <Col md={12} key={index}>{company_group.company_en}</Col>
                      <Col md={12} key={index}>{company_group.company_cn}</Col>
                      <br/>
                      </div>})}
              </div>
          </DetailBox>
        </Mediaquery>

        <Mediaquery maxWidth={991}>
          <StatusBox>
            <BuildBox>
              <div>{companyThai}</div>
              <div>{companyEng}</div>
              <div>{companyChi}</div>
            </BuildBox>
            <BuildBox2 style={{ marginBottom: "20px" }}>
              <div>{ComPositionThai}</div>
              <div>{ComPositionEng}</div>
              <div>{ComPositionChi}</div>
            </BuildBox2>
          </StatusBox>
          <DetailBox>
              <div style={{paddingLeft: "15px"}}>
                ประเภทธุรกิจ : {company_group.map((company_group, index) => { 
                return <div>
                      <Col md={12} key={index}>{company_group.company_th}</Col>
                      <Col md={12} key={index}>{company_group.company_en}</Col>
                      <Col md={12} key={index}>{company_group.company_cn}</Col>
                      <br/>
                      </div>})}
              </div>
          </DetailBox>
        </Mediaquery>

        <DetailBox>
          <div>{locationThai}</div>
          <div>{locationEng}</div>
          <div>{locationCn}</div>
        </DetailBox>
        {PageState ? null : <div>{renderFooter()}</div>}
      </div>
    );
  };

  return (
    <Container>
      {PageState ? (
        <Box>{renderPage()}</Box>
      ) : (
        <Box style={{ borderBottom: "1px solid #b9ab60" }}>{renderPage()}</Box>
      )}
    </Container>
  );
};

export default MemberDetailCard;
