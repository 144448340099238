import styled from "styled-components";
import { Carousel, Row } from "react-bootstrap";
export const PicNews = styled.img`
  width: 13.53vw;
  height: 13.53vw;
  object-fit: cover;
  border-radius: 50%
`;

export const ContainerName = styled.div`
  border-bottom: 2px solid #b9ab60;
`;

export const NameThai = styled.div`
  font-family: promptLight;
  color: #0a2980;
  font-size: 40px;
  font-weight: bold;
`;
export const NameEng = styled.div`
  font-family: cinzel;
  color: #0a2980;
  font-size: 20px;
`;
export const PositionEng = styled.div`
  font-family: cinzel;
  color: #0a2980;
  font-size: 35px;
`;
export const RowCustom = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5vw;
  @media (max-width: 767px) {
    padding: 0 10vw;
  }
`;
