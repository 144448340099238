import React from "react";
import {
  Card,
  PicBoxFix,
  Topic,
  RedLine,
  BlackLine,
  UnderLineBox,
  Title,
  TextDetail,
} from "./styled";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const NewsCard = ({ data, link, from}) => {
  function refreshPage() {
    window.location.reload(false);
  }
  const history = useHistory();
  const OnClickDetailNews = () => {
    history.push({ pathname: "/DetailNews" });
  };
  // console.log('data',data)
  const { t,i18n } = useTranslation();
  var title = ''
  var short_description = ''
  if(i18n.language == 'en'){
    title = data.title_en
    short_description = data.short_description_en
  }else if(i18n.language == 'th'){
    title = data.title_th
    short_description = data.short_description_th
  }else if(i18n.language == 'ch'){
    title = data.title_cn
    short_description = data.short_description_cn
  }
  return (
    <button style={{border: "none", backgroundColor: "white"}} onClick={refreshPage}>
    <Link style={{textDecoration:'none'}} to={{pathname: `/DetailNews/${from}/${link}`}}>
      <Card style={{height:'300px'}} onClick={OnClickDetailNews}>
        <PicBoxFix src={data.img} />
        <Topic style={{height:'45%'}}>
          <Title style={{textAlign: "left"}}>{title}</Title>
          <UnderLineBox>
            <RedLine />
            <BlackLine />
          </UnderLineBox>
          <TextDetail style={{textAlign: "left"}}>{short_description}</TextDetail>
        </Topic>
      </Card>
    </Link>
    </button>
  );
};

export default NewsCard;
