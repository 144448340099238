import React, { useState, useEffect } from "react";
import MainTopicText from "modules/MainTopicText";
import ThirdTopicText from "modules/ThirdTopicText";
import facebook from "modules/images/facebook.svg";
import twitter from "modules/images/twitter.svg";
import line from "modules/images/line.svg";
import Mediaquery from "react-responsive";
import { Container, Row, Col } from "react-bootstrap";
import {
  PicBox,
  ShareBox,
  SocialPic,
  ButtonCustom,
  ButtonDate,
  Detail,
  Picrow,
  Picinrow,
  RedLine,
  BlackLine,
  UnderLineBox,
  BottonTabAn
} from "./styled";
import DetailText from "modules/DetailText";
import NewsCard from "modules/NewsCard";
import CarouselMain from "modules/CarouselMain";
import axios from "axios";
import { API_IP, CONFIG, month_th, month_en, month_cn, day_th, day_en } from '../../constant/constant'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
} from "react-share";
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';
import register from '../../modules/PDF/register.pdf'
// import Carousel from 'react-spring-3d-carousel';
import {Carousel} from '3d-react-carousal';
import ButtonPush from "modules/ButtonPush";
import { useHistory, Link } from "react-router-dom";
const RenderBusinessPage = ({ namePage, DetailNews, data }) => {
  const [activity, setactivity] = useState([]);
  let history = useHistory();
    const goToPreviousPath = () => {
      history.push({ pathname: "/home" });
  }
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Relationsnews?start=0&stop=0", CONFIG)
      .then(function (response) {
        setactivity(response.data[0]);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);
  const { t,i18n } = useTranslation();
  if(DetailNews != undefined && DetailNews.length != 0 && DetailNews != null){
    // console.log('DetailNews if',DetailNews)
    var date = moment(DetailNews.date_start,'YYYY-MM-DD')
    var month = date.format('M')
    // if(i18n.language == 'th'){
    //   var monthtxt = month_th[month]
    // }else if(i18n.language == 'en'){
    //   var monthtxt = month_en[month]
    // }else if(i18n.language == 'ch'){
    //   var monthtxt = month_en[month]
    // }
    // var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
    var year = date.year()
    var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
    var days = date.format('D')
    var description = ''
    var dateshow = ''
    if(i18n.language == 'en'){
      description = DetailNews.description_en
      dateshow = `${yeartxt}/${month}/${days}`
    }else if(i18n.language == 'th'){
      description = DetailNews.description_th
      dateshow = `${days}/${month}/${yeartxt}`
    }else if(i18n.language == 'ch'){
      description = DetailNews.description_cn
      dateshow = `${yeartxt}/${month}/${days}`
    }

    var slides = []
    var img_slide = DetailNews.img_slide
    for(var i = 0; i<img_slide.length;i++){
      // var temp_data = {
      //   key:i,
      //   content: <img src={img_slide[0].img} alt="1" />
      // }
      slides.push(<img src={img_slide[i]} alt="1" />)
    }
    console.log('DetailNews',DetailNews)
    return (
      <div style={{ marginBottom: "50px" }}>
        <Container>
          {DetailNews ? null : (
            <MainTopicText state={i18n.language} name={t('navbar.economy')} />
          )}
          <Row>
            <Col lg>
              <ThirdTopicText name={namePage} />
  
              {DetailNews&&<Mediaquery minWidth={1024}>
                <ShareBox>
                  <ButtonDate>
                  <div>{dateshow}</div>
                  </ButtonDate>
                  {/* <FacebookShareButton
                    url={window.location.href}
                    quote={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#3B5999" }}>
                      <SocialPic src={facebook} alt="facebook" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom> 
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#03A9F4" }}>
                      <SocialPic src={twitter} alt="twitter" />
                      <div>Tweet</div>
                    </ButtonCustom>
                  </TwitterShareButton>
                  
                  <LineShareButton
                    url={window.location.href}
                    title={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#00c300" }}>
                      <SocialPic src={line} alt="line" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom>
                  </LineShareButton> */}
                  
                </ShareBox>
              </Mediaquery>}
  
              {DetailNews &&<PicBox src={DetailNews.img} alt="video" />}
              {DetailNews &&<Detail dangerouslySetInnerHTML={{__html: description}}/>}
            </Col>
          </Row>
        </Container>
        {DetailNews &&<Container style={{ marginBottom: "20px" }}>
          {/* <div style={{ width: "80%", margin: "0 auto" }}>
            <Carousel slides={slides} autoplay={true} interval={5000}/>
          </div> */}
          <CarouselMain Page="true" data={DetailNews.img_slide}/>
          <Picrow>
            {
             DetailNews.img_slide.map((item, index)=>{
                return(
                  <Picinrow key={index} src={item.img} alt="fisrtSlide" />
                )
              })
            }
          </Picrow>
        </Container>}

        {DetailNews.pdf.map((item, index)=>{
          var namePdf = item.pdf.split('/');
          console.log('namePdf', namePdf);
          var countnamePdf = namePdf.length-1;
          console.log('count!!!!', countnamePdf);
          return(<Container>
            <div style={{width:'100%',alignItems:'center',marginBottom:'20px'}}>
                {/* <div style={{width:'60%',marginLeft:'auto',marginRight:'auto'}}>
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js">
                      <div style={{ height: '750px' }}>
                          <Viewer fileUrl={item.pdf} />
                      </div>
                  </Worker>
                </div> */}
                <div><b style={{color:"#b9ab60"}}>{namePdf[countnamePdf]}</b></div>
                <UnderLineBox>
                  <RedLine />
                  <BlackLine />
                </UnderLineBox>
                <a style={{color:"black"}} href={item.pdf} target="_blank" download>Download FILE</a>
            </div>
            </Container>)
        })
        }
        <Container>
          <DetailText state={i18n.language} name="ข่าวที่น่าสนใจ" />
          <Row md={4}>
            {
              activity.map((item, index)=>{
                  // console.log('wow!!!', item.relationsnews_id);
                return(
                  <Col xs={12} key={index}>
                    <NewsCard data={item} link={item.relationsnews_id} from={'relationsnews'}/>
                  </Col>
                )
              })
            }
            
          </Row>
        </Container>
        <BottonTabAn onClick={goToPreviousPath} >
        <ButtonPush  name={t('MemberDirectory.goBack')}/>
        </BottonTabAn>
      </div>
    );
  }else if(data != undefined && data.length > 0){
    var date = moment(data[0].modified_date,'YYYY-MM-DD')
    var month = date.format('M')
    var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
    var year = date.year()
    var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
    var days = date.format('D')
    var description = ''
    if(i18n.language == 'en'){
      description = data[0].description_en
    }else if(i18n.language == 'th'){
      description = data[0].description_th
    }else if(i18n.language == 'ch'){
      description = data[0].description_ch
    }
    
    var slides = []
    if(data.length > 0){
      var img_slide = data[0].img_slide
      for(var i = 0; i<img_slide.length;i++){
        // var temp_data = {
        //   key:i,
        //   content: <img src={img_slide[0].img} alt="8" />
        // }
        slides.push(<img src={img_slide[1]} alt="1" />)
      }
    }
    console.log('slides',slides)
    return (
      <div style={{ marginBottom: "50px" }}>
        <Container>
          {DetailNews ? null : (
            <MainTopicText state={i18n.language} name={t('navbar.economy')} />
          )}
          <Row>
            <Col lg>
              <ThirdTopicText name={namePage} />
  
              {data.length > 0 &&<Mediaquery minWidth={1024}>
              <ShareBox>
                  <ButtonDate>
                  <div>{`${yeartxt}/${month}/${days}`}</div>
                  </ButtonDate>
                  {/* <FacebookShareButton
                    url={window.location.href}
                    quote={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#3B5999" }}>
                      <SocialPic src={facebook} alt="facebook" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom> 
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={window.location.href}
                    title={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#03A9F4" }}>
                      <SocialPic src={twitter} alt="twitter" />
                      <div>Tweet</div>
                    </ButtonCustom>
                  </TwitterShareButton>
                  
                  <LineShareButton
                    url={window.location.href}
                    title={namePage}
                    className="Demo__some-network__share-button"
                  >
                    <ButtonCustom style={{ backgroundColor: "#00c300" }}>
                      <SocialPic src={line} alt="line" />
                      <div>{t('socialMedia.share')}</div>
                    </ButtonCustom>
                  </LineShareButton> */}
                  
                </ShareBox>
              </Mediaquery>}
              {data.length > 0 &&<PicBox src={data[0].img} alt="video" />}
              {data.length > 0 &&<Detail dangerouslySetInnerHTML={{__html: description}}/>}
            </Col>
          </Row>
        </Container>
        {data.length > 0 &&<Container style={{ marginBottom: "20px" }}>
          {/* <div style={{ width: "80%", height: "300px", margin: "0 auto" }}>
            <Carousel slides={slides} autoplay={true} interval={1000}/>
          </div> */}
          <CarouselMain Page="true" data={data[0].img_slide}/>
          <Picrow>
            {
              data[0].img_slide.map((item, index)=>{
                return(
                  <Picinrow src={item.img} alt="fisrtSlide" />
                )
              })
            }
          </Picrow>
        </Container>}
        <Container>
          <DetailText state={i18n.language} name="ข่าวที่น่าสนใจ" />
          <Row md={4}>
            {
              activity.map((item, index)=>{
                return(
                  <Col xs={12}>
                    <NewsCard data={item}  link={item.relationsnews_id} from={'relationsnews'}/>
                  </Col>
                )
              })
            }
            
          </Row>
        </Container>
        <BottonTabAn onClick={goToPreviousPath} >
        <ButtonPush  name={t('MemberDirectory.goBack')}/>
        </BottonTabAn>
      </div>
    );
  }else{
    return (
      <Container/>
    )
   
  }
  
};

export default RenderBusinessPage;
