import React from "react";
import logo from "modules/TopicNav/images/logo.svg";
import { Navbar, NavDropdown, Nav, Row, Col } from "react-bootstrap";
import { NavCustom, PicBox, Box, LanBox } from "./styled";
import { useTranslation } from "react-i18next";
const NavSlide = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const ChangeLanguage = (language) => {
    i18n.changeLanguage(language)
  };
  return (
    <NavCustom defaultactivekey="/" collapseOnSelect expand="lg">
      <Navbar.Brand href="/">
          <PicBox src={logo} alt={logo} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <Nav.Link href="/">{t('navbar.home')}</Nav.Link>
          <NavDropdown title={t('navbar.about')}>
            <NavDropdown.Item href="/visionmission">{t('navbar.dropdown.vision_mission')}</NavDropdown.Item>
            <NavDropdown.Item href="/about">{t('navbar.dropdown.about_thaichinese')}</NavDropdown.Item>
            <NavDropdown.Item href="/president">{t('navbar.dropdown.about_president_thaichinese')}</NavDropdown.Item>
            <NavDropdown.Item href="/boardMember">
            {t('navbar.dropdown.board_member')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/honorMember">
            {t('navbar.dropdown.hornor_member')}
            </NavDropdown.Item>
          </NavDropdown>
          
          <NavDropdown title={t('navbar.news')}>
            <NavDropdown.Item href="/news">{t('navbar.activity')}</NavDropdown.Item>
            <NavDropdown.Item href="/activity">{t('navbar.dropdown.relation')}</NavDropdown.Item>
            <NavDropdown.Item href="/media">{t('navbar.dropdown.relation_media')}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t('navbar.economy')}>
            <NavDropdown.Item href="/business">
              {t('navbar.dropdown.ThaiChinese_articles')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/confident">
            {t('navbar.dropdown.ThaiChinese_belief')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/ThaiInvest">
            {t('navbar.dropdown.Capital_investment_thai')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/ChineseInvest">
            {t('navbar.dropdown.Capital_investment_china')}
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t('navbar.member')}>
           
            <NavDropdown.Item href="/member">{t('navbar.dropdown.member')}</NavDropdown.Item>
            <NavDropdown.Item href="/action/5.4"> {t('navbar.dropdown.register')}</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title={t('navbar.department')}>
            <NavDropdown.Item href="/depathment/Entrepreneurs_Convention">
            {t('navbar.dropdown.Entrepreneurs_Convention')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/depathment/Joint_Foreign">
            {t('navbar.dropdown.Joint_Foreign')}
            </NavDropdown.Item>
            <NavDropdown.Item href="/depathment/ThaiCC_Related">
            {t('navbar.dropdown.ThaiCC_Related')}
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/contact">{t('navbar.contactus')}</Nav.Link>
        </Nav>
        <Box style={{ paddingTop: '0'}}>
          <Row>
            <Col style={{padding: "2.5px", paddingLeft: "14px"}}><LanBox style={{ marginBottom: "4px",backgroundColor:i18n.language=='th'?'rgba(49, 46, 30, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('th')}>TH</LanBox></Col>
            <Col style={{padding: "2.5px"}}><LanBox style={{ marginBottom: "4px" ,backgroundColor:i18n.language=='en'?'rgba(49, 46, 30, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('en')}>ENG</LanBox></Col>
            <Col style={{padding: "2.5px"}}><LanBox style={{backgroundColor:i18n.language=='ch'?'rgba(49, 46, 30, 0.8)': 'rgba(185, 171, 96, 0.5)'}} onClick={()=>ChangeLanguage('ch')}>中文</LanBox></Col>
          </Row>
        </Box>
      </Navbar.Collapse>
    </NavCustom>
  );
};

export default NavSlide;
