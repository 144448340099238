import styled from "styled-components";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
export const Box = styled.div`
  text-align: center;
  font-family: promptLight;
  font-weight: bold;
  font-size: 18px;
`;

export const TopicText = styled.div`
  font-size: 23px;
  border-bottom: 2px solid #0a2980;
  width: 300px;
  text-align: center;
`;

export const TextBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`;

export const LinkCustom = styled(Link)`
  cursor: pointer;
  color: #0a2980;
  :hover {
    color: #0a2980;
    text-decoration: initial;
  }
`;
export const ModalCustom = styled(Modal)`
  padding-left: 0px;
  padding: 0;
  font-family: promptLight;
  font-size: 18px;
  .modal-content {
    height: fit-content;
    width: fit-content;
    background-color: #fff;
    border-radius: 20px;
    border: 2px solid #b9ab60;
    padding: 20px;
    color: #0a2980;
    cursor: pointer;
    width: auto
  }
  .modal-dialog-centered {
    justify-content: center;
  }
  .modal-header {
    border: none;
    padding: 0;
  }
  .modal-header .close {
    padding: 0.5rem 0.75rem 0 0;
  }
`;
