import React from 'react'
import {TopicBox, PicBox, TextBox, TextBox2} from './styled'
import DetailAc2 from 'modules/images/DetailAc2.svg'

const DetailText = ({name, state}) => {
    const renderDetailText = () => {
        if (state == 'th' || state == 'ch') {
            return (
                <TextBox>{name}</TextBox>
            )
        }
        else {
            return (
                <TextBox2>{name}</TextBox2>
            )
        }
    }
    return (
        <TopicBox>
            {renderDetailText()}
            <PicBox src={DetailAc2} alt='DetailAc' />
        </TopicBox>
    )
}

export default DetailText