import styled from "styled-components";

export const PicBox = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;
export const PicBoxFix = styled.img`
  width: 100%;
  height: 50%;
`;
export const Card = styled.div`
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 15px;
`;

export const BodyBox = styled.div`
  font-family: "promptLight";
`;

export const Topic = styled.div`
  font-family: "promptLight";
  font-size: 20px;
  color: #918338;
  font-weight: bold;
  margin: 10px 0 10px 0;
  @media (max-width: 767px) {
        font-size: 16px;
	}
`;

export const RedLine = styled.div`
    background-color: #a00a08;
    height: 2.2px;
    width: 110px;
    bottom: 0;
    position: relative;
`

export const BlackLine = styled.div`
    background-color: #000;
    height: 2.2px;
    width: 20px;
    position: relative;
`
export const UnderLineBox = styled.div`
    display: flex;
    justify-content: space-between;
    width: 135px;
    margin-top: 7px;
`
export const Title = styled.div`
  display: flex;
  line-height: 1.2em;
  height: 1.2em;
  overflow: hidden;
`
export const TextDetail = styled.div`
    color: #0a2980;
    font-size: 0.7rem;
    margin-top: 7px;
    line-height: 1.2em;
    height: 3.6em;
    overflow: hidden;
`
export const Datecreate = styled.div`
  display: flex;
  line-height: 1.2em;
  height: 1.2em;
  overflow: hidden;
  align-items: center;
`

export const DateDetail = styled.div`
    color: #0a2980;
    font-size: 0.7rem;
    line-height: 1.2em;
    height: 1.2em;
    overflow: hidden;
    margin-left: 7px;
`