import React, { useState, useEffect } from "react";
import axios from "axios";
import SecondTopicText from "modules/SecondTopicText";
import MainTopicText from "modules/MainTopicText";
import ReactPlayer from "react-player";
import NewsCardHomePage from "modules/NewsCardHomePage";
import NewsCardHighlight from "modules/NewsCardHighlight"
import { Row, Col, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  PicBox,
  Head1,
  Head2,
  DetailAbout,
  ButtonTab,
  Circle,
  ButtonEconomic,
  PicBoxEconomic,
  BottonTabAn,
  PicBoxMedia,
  BackgroundMember,
  ContainerCustom,
  ButtonTabMember,
  BackgroundInfo,
  ButtonTry,
  NewsText,
  NewsTextContainer,
  HomeButton,
  TextButton,
  Picdiv,
  Buttondiv,
  VideoPic,
  PicImg,
  Datecreate,
  DateDetail
} from "./styled";
import {
  Topic,
  UnderLineBox,
  RedLine,
  BlackLine,
  BodyBox,
} from "modules/NewsCard/styled";
import logo from "modules/TopicNav/images/logo.svg";
import ButtonPush from "modules/ButtonPush";
import Mediaquery from "react-responsive";
import CarouselMain from "modules/CarouselMain";
import pesident from "modules/images/pesident.svg";
import BlueButton from "modules/images/BlueButton.svg";
import yellowButton from "modules/images/yellowButton.svg";
import { API_IP, CONFIG, month_th, month_en, day_th, day_en } from '../../constant/constant'
import moment from 'moment'
import { FaCalendarAlt } from 'react-icons/fa'
import { useTranslation } from "react-i18next";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import NewsCard from "modules/NewsCard";
const HomePage = () => {
  const history = useHistory();
  const OnClickActivity = (item) => {
    history.push({ pathname: `/DetailNews/activity/${item.activitynews_id}` });
  };
  const OnClickActivity_more = () => {
    history.push({ pathname: "/activity" });
  };

  const OnClickInfo = (item) => {
    history.push({ pathname: `/DetailNews/relationsnews/${item.relationsnews_id}` });
  };
  const OnClickInfo_more = () => {
    history.push({ pathname: "/news" });
  };

  const OnClickMedia = (item) => {
    history.push({ pathname: `/DetailNews/media/${item.media_id}` });
  };
  const OnClickMedia_more = () => {
    history.push({ pathname: "/media" });
  };

  const OnClickAbout = () => {
    history.push({ pathname: "/about" });
  };

  const OnClickVisionmission = () => {
    history.push({ pathname: "/visionmission" });
  };

  const OnClickMember = () => {
    history.push({ pathname: "/member" });
  };

  const OnClickRegister= () => {
    history.push({ pathname: "/register" });
  };

  const OnClickConfident = () => {
    history.push({ pathname: "/confident" });
  };

  const OnClickThaiInvest = () => {
    history.push({ pathname: "/ThaiInvest" });
  };

  const OnClickChineseInvest = () => {
    history.push({ pathname: "/ChineseInvest" });
  };

  const OnClickBusiness = () => {
    history.push({ pathname: "/business" });
  };
  const HighlightPart = () => {
    const { t,i18n } = useTranslation();
    const [activity, setactivity] = useState([]);
    const [relationsnews, setrelationsnews] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        axios
          .get(API_IP + "/Activitynews?start=0&stop=0", CONFIG)
          .then(function (response) {
            setactivity(response.data[0]);
          })
          .catch(function (error) {
        })

        axios
          .get(API_IP + "/Relationsnews?start=0&stop=0", CONFIG)
          .then(function (response) {
            setrelationsnews(response.data[0]);
          })
          .catch(function (error) {
        })
      };
      fetchData();
    }, []);
    var activity_3 = activity.slice(Math.max(activity.length - 3, 0))
    var relationsnews_4 = relationsnews.slice(Math.max(relationsnews.length - 4, 0))
    var All = activity_3.concat(relationsnews_4);
    var All_sort = All.sort(function(a, b){
      return moment(a.created_date,'YYYY-MM-DD HH:mm:ss') - moment(b.created_date,'YYYY-MM-DD HH:mm:ss')
    });
    var All_4 = All_sort.slice(Math.max(All_sort.length - 4, 0))

    if(All_sort.length > 0){
    var date_0 = moment(All_sort[0].created_date,'YYYY-MM-DD HH:mm:ss')
    var month_0 = date_0.month()
    var monthtxt_0 = i18n.language == 'th'?month_th[month_0]:month_en[month_0]
    var year_0 = date_0.year()
    var yeartxt_0 =  i18n.language == 'th'?parseInt(year_0)+543:parseInt(year_0)
    var days_0 = i18n.language == 'th'?day_th[date_0.day()]:day_en[date_0.day()]

    var date_1 = moment(All_sort[1].created_date,'YYYY-MM-DD HH:mm:ss')
    var month_1 = date_1.month()
    var monthtxt_1 = i18n.language == 'th'?month_th[month_1]:month_en[month_1]
    var year_1 = date_1.year()
    var yeartxt_1 =  i18n.language == 'th'?parseInt(year_1)+543:parseInt(year_1)
    var days_1 = i18n.language == 'th'?day_th[date_1.day()]:day_en[date_1.day()]

    var date_2 = moment(All_sort[2].created_date,'YYYY-MM-DD HH:mm:ss')
    var month_2 = date_2.month()
    var monthtxt_2 = i18n.language == 'th'?month_th[month_2]:month_en[month_2]
    var year_2 = date_2.year()
    var yeartxt_2 =  i18n.language == 'th'?parseInt(year_2)+543:parseInt(year_2)
    var days_2 = i18n.language == 'th'?day_th[date_2.day()]:day_en[date_2.day()]

    var title_0 = ''
    var short_description_0 = ''
    if(i18n.language == 'en'){
      title_0 = All_sort[0].title_en
      short_description_0 = All_sort[0].short_description_en
    }else if(i18n.language == 'th'){
      title_0 = All_sort[0].title_th
      short_description_0 = All_sort[0].short_description_th
    }else if(i18n.language == 'ch'){
      title_0 = All_sort[0].title_cn
      short_description_0 = All_sort[0].short_description_cn
    }
    if(All_sort.length > 3){
      var title_1 = ''
      if(i18n.language == 'en'){
        title_1 = All_sort[1].title_en
      }else if(i18n.language == 'th'){
        title_1 = All_sort[1].title_th
      }else if(i18n.language == 'ch'){
        title_1 = All_sort[1].title_cn
      }
    }
    if(All_sort.length > 4){
      var title_2 = ''
      if(i18n.language == 'en'){
        title_2 = All_sort[2].title_en
      }else if(i18n.language == 'th'){
        title_2 = All_sort[2].title_th
      }else if(i18n.language == 'ch'){
        title_2 = All_sort[2].title_cn
      }
    }
  }
    return (
      <ContainerCustom>
        <MainTopicText state={i18n.language} name={t('home.highlight')} />
        <Row onClick={OnClickActivity_more}>
          {All_sort.length > 0 && <Col md={{ span: 8 }}>
            <NewsTextContainer>
              <PicBoxEconomic
                style={{ height: "450px"}}
                src={All_sort[0].img}
                alt="newsMedia"
              />
              <NewsText>
                <Topic style={{ marginTop: "0" }}>
                  <div style={{ color: "#fff" }}>{title_0}</div>
                  <UnderLineBox>
                    <RedLine style={{ backgroundColor: "#fdd600" }} />
                    <BlackLine style={{ backgroundColor: "#fff" }} />
                  </UnderLineBox>
                </Topic>
                <BodyBox dangerouslySetInnerHTML={{__html: short_description_0}}/>
                <Datecreate>
                  <FaCalendarAlt size='0.7rem' style={{ color: '#ffffff'}}/>
                  <DateDetail>{`${date_0.day()} ${monthtxt_0} ${yeartxt_0}`}</DateDetail>
                </Datecreate>
              </NewsText>
            </NewsTextContainer>
          </Col>}
          <Mediaquery minWidth={768}>
            <Col md={{ span: 4 }}>
              {All_sort.length > 3 &&<NewsTextContainer>
                <PicBoxMedia
                  style={{ height: "212.5px" }}
                  src={All_sort[1].img}
                  alt="newsMedia"
                />
                <NewsText>
                  <Topic style={{ marginTop: "0" }}>
                    <div style={{ color: "#fff" }}>{title_1}</div>
                    <UnderLineBox>
                      <RedLine style={{ backgroundColor: "#fdd600" }} />
                      <BlackLine style={{ backgroundColor: "#fff" }} />
                    </UnderLineBox>
                  </Topic>
                  <Datecreate>
                    <FaCalendarAlt size='0.7rem' style={{ color: '#ffffff'}}/>
                    <DateDetail>{`${date_1.day()} ${monthtxt_1} ${yeartxt_1}`}</DateDetail>
                  </Datecreate>
                </NewsText>
              </NewsTextContainer>}
              {All_sort.length > 4 &&<NewsTextContainer>
                <PicBoxMedia
                  style={{ height: "212.5px" }}
                  src={All_sort[2].img}
                  alt="newsMedia"
                />
                <NewsText>
                  <Topic style={{ marginTop: "0" }}>
                    <div style={{ color: "#fff" }}>{title_2}</div>
                    <UnderLineBox>
                      <RedLine style={{ backgroundColor: "#fdd600" }} />
                      <BlackLine style={{ backgroundColor: "#fff" }} />
                    </UnderLineBox>
                  </Topic>
                  <Datecreate>
                    <FaCalendarAlt size='0.7rem' style={{ color: '#ffffff'}}/>
                    <DateDetail>{`${date_2.day()} ${monthtxt_2} ${yeartxt_2}`}</DateDetail>
                  </Datecreate>
                </NewsText>
              </NewsTextContainer>}
            </Col>
          </Mediaquery>
        </Row>
        <Row md={4}>
          {
            All_4.map((item, index)=>{
              return(
                <Col xs={12} key={index}>
                  <NewsCardHighlight data={item} link={item.activitynews_id !== undefined?item.activitynews_id:item.relationsnews_id} />
                </Col>
              )
              
            })
          }
          
        </Row>
      </ContainerCustom>
    );
  };

  const ActivityPart = () => {
    const [activity, setactivity] = useState([]);
    const { t,i18n } = useTranslation();
    useEffect(() => {
      const fetchData = async () => {
        axios
          .get(API_IP + "/Relationsnews?start=0&stop=0", CONFIG)
          .then(function (response) {
            setactivity(response.data[0]);
          })
          .catch(function (error) {
        })
      };
      fetchData();
    }, []);

    return (
      <ContainerCustom>
        <SecondTopicText state={i18n.language} name={t('navbar.activity')} />
        <Row >
          <Col md={{ span: 8 }}>
            {activity == 0 ? (
                    <div></div>
                ) : (
              activity.map((item, index)=>{
                if(index <= 2){
                  var date = moment(item.created_date,'YYYY-MM-DD HH:mm:ss')
                  var month = date.month()
                  var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
                  var year = date.year()
                  var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
                  var days = date.format('D')

                  var title = ''
                  var short_description = ''
                  if(i18n.language == 'en'){
                    title = item.title_en
                    short_description = item.short_description_en
                  }else if(i18n.language == 'th'){
                    title = item.title_th
                    short_description = item.short_description_th
                  }else if(i18n.language == 'ch'){
                    title = item.title_cn
                    short_description = item.short_description_cn
                  }
                  return(
                    <Row style={{ marginBottom: "20px" }} key={index} onClick={()=>OnClickInfo(item)}>
                      <Col md={{ span: 4 }}>
                        <PicBoxMedia style={{objectFit:'cover', height: 'auto'}} src={item.img} alt="newsMedia" />
                      </Col>
                      <Col md={{ span: 8 }}>
                        <Topic style={{ marginTop: "0" }}>
                          <div>{title}</div>
                          <UnderLineBox>
                            <RedLine />
                            <BlackLine />
                          </UnderLineBox>
                        </Topic>
                        <BodyBox>{short_description}</BodyBox>
                        <Datecreate>
                          <FaCalendarAlt size='0.7rem' style={{ color: '#08297f'}}/>
                          <DateDetail style={{color: '#08297f'}}>{`${days} ${monthtxt} ${yeartxt}`}</DateDetail>
                        </Datecreate>
                      </Col>
                    </Row>
                    )
                }
                
              })
            )
            }
            
           
          </Col>
          <Mediaquery minWidth={768}>
            <Col md={{ span: 4 }}>
            <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FThaiChineseChamberofCommerce&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=831808740659819" width="340" height="500" scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
            </Col>
          </Mediaquery>
        </Row>
        <BottonTabAn onClick={OnClickInfo_more}>
          <ButtonPush name={t('home.more')} />
        </BottonTabAn>
      </ContainerCustom>
    );
  };

  const InformationPart = () => {
    const [relationsnews, setrelationsnews] = useState([]);
    const { t,i18n } = useTranslation();
    useEffect(() => {
      const fetchData = async () => {
        axios
          .get(API_IP + "/Activitynews?start=0&stop=0", CONFIG)
          .then(function (response) {
            setrelationsnews(response.data[0]);
          })
          .catch(function (error) {
        })
      
      };
      fetchData();
    }, []);
    return (
      <div>
        <Container>
          <SecondTopicText
            state={i18n.language}
            name={t('navbar.news')}
          />
        </Container>
        <BackgroundInfo>
          <ContainerCustom>
            <Row md={3} >
              {relationsnews == 0 ? (
                    <div></div>
                ) : (
                relationsnews.map((item, index)=>{
                  if(index < 3){
                    return(
                      <Col xs={12} key={index} onClick={()=>OnClickActivity(item)}>
                        <NewsCardHomePage data={item} />
                      </Col>
                    )
                  }
                })
                )
              }
            </Row>
            <ButtonTry onClick={OnClickActivity_more}>
              <ButtonPush name={t('home.more')} />
            </ButtonTry>
          </ContainerCustom>
        </BackgroundInfo>
      </div>
    );
  };

  const MediaPart = () => {
    const [media, setmedia] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        axios
        .get(API_IP + "/Relationsnews/media?start=0&stop=0", CONFIG)
        .then(function (response) {
          setmedia(response.data[0]);
        })
        .catch(function (error) {
        })
      };
      fetchData();
      console.log('ว้าววว',media.length);
    }, []);
    const { t,i18n } = useTranslation();
    if(media.length > 0){
      var date = moment(media[0].created_date,'YYYY-MM-DD HH:mm:ss')
      var month = date.month()
      var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
      var year = date.year()
      var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
      var days = i18n.language == 'th'?day_th[date.day()]:day_en[date.day()]

      var title_0 = ''
      if(i18n.language == 'en'){
        title_0 = media[0].title_en
      }else if(i18n.language == 'th'){
        title_0 = media[0].title_th
      }else if(i18n.language == 'ch'){
        title_0 = media[0].title_cn
      }
    }
    return (
      <div>
        <ContainerCustom>
          <MainTopicText state={i18n.language} name={t('navbar.dropdown.relation_media')} />

          <Mediaquery minWidth={992}>
            <Row>
              {media.length > 0 && <Col md={{ span: 9 }} style={{ marginBottom: "15px" }}>
                <VideoPic>
                  <ReactPlayer
                    style={{ position: "absolute", top: "0", left: "0" }}
                    controls
                    url={media[0].link}
                    width="100%"
                    height="100%"
                  />
                </VideoPic>
                <DetailAbout style={{ marginTop: "10", color: "#0a2980" }}>
                  {title_0}
                </DetailAbout>
                <Datecreate>
                  <FaCalendarAlt size='0.7rem' style={{ color: '#08297f'}}/>
                  <DateDetail style={{color: '#08297f'}}>{`${date.day()} ${monthtxt} ${yeartxt}`}</DateDetail>
                </Datecreate>
              </Col>}
              <Col md={{ span: 3 }}>
                {media == 0 ? (
                      <div></div>
                  ) : (
                  media.map((item, index)=>{
                    if(index < 3){
                    var title = ''
                    if(i18n.language == 'en'){
                      title =item.title_en
                    }else if(i18n.language == 'th'){
                      title = item.title_th
                    }else if(i18n.language == 'ch'){
                      title = item.title_cn
                    }
                    return(
                      <Col style={{ marginBottom: "10px" }} key={index} onClick={()=>OnClickMedia(item)}>
                        <PicImg src={item.img} alt="videoPic" />
                        <DetailAbout style={{ margin: "0", color: "#0a2980" }}>
                          {title}
                        </DetailAbout>
                      </Col>
                    )
                    }
                  })
                  )
                }
               
                
              </Col>
            </Row>
          </Mediaquery>
          <BottonTabAn onClick={OnClickMedia_more}>
            <ButtonPush name={t('home.more')} />
          </BottonTabAn>
        </ContainerCustom>
      </div>
    );
  };

  const BusinessPart = () => {
    const [business, setarticles] = useState([]);
    const { t,i18n } = useTranslation();
    useEffect(() => {
      const fetchData = async () => {
        axios
          .get(API_IP + "/Economy/ThaiChinese_articles?start=0&stop=0", CONFIG)
          .then(function (response) {
            setarticles(response.data[0]);
            console.log(business.length);
          })
          .catch(function (error) {
        })
      
      };
      fetchData();
    }, []);
    
    return (
      <ContainerCustom>
        <SecondTopicText
          state={i18n.language}
          name={t('navbar.economy')}
        />
        <Row md={3}>
          <Col xs={12}>
            <Buttondiv onClick={OnClickConfident}>
              <ButtonEconomic style={{borderRadius:10}} src={yellowButton} alt="button" />
              <TextButton>
                <div>{t('home.ThaiChinese_belief')}</div>
                <div>{t('home.relation')}</div>
              </TextButton>
            </Buttondiv>
          </Col>
          <Col xs={12}>
            <Buttondiv onClick={OnClickThaiInvest}>
              <ButtonEconomic style={{borderRadius:10}} src={yellowButton} alt="button" />
              <TextButton>{t('navbar.dropdown.Capital_investment_thai')}</TextButton>
            </Buttondiv>
          </Col>
          <Col xs={12}>
            <Buttondiv onClick={OnClickChineseInvest}>
              <ButtonEconomic style={{borderRadius:10}} src={yellowButton} alt="button" />
              <TextButton>{t('navbar.dropdown.Capital_investment_china')}</TextButton>
            </Buttondiv>
          </Col>
        </Row>
        <Row md={4} >
        {business == 0 ? (
              <div></div>
          ) : (
            business.map((item, index)=>{
              if(index < 4){
                
                var date = moment(item.created_date,'YYYY-MM-DD HH:mm:ss')
                var month = date.format('M')
                var monthtxt = i18n.language == 'th'?month_th[month]:month_en[month]
                var year = date.year()
                var yeartxt =  i18n.language == 'th'?parseInt(year)+543:parseInt(year)
                var days = date.format('D')
                var title = ''
                if(i18n.language == 'en'){
                  title =item.title_en
                }else if(i18n.language == 'th'){
                  title = item.title_th
                }else if(i18n.language == 'ch'){
                  title = item.title_cn
                }
                return(
                  <Col xs={12} key={index}>
                    <NewsCard data={item} link={item.business_economy_id} from={'articles'}/>
                    {/* <NewsTextContainer style={{height:'490px'}}>
                      <PicBoxEconomic src={item.img} alt="newseco" />
                      <NewsText style={{bottom:'0px'}}>
                        <Topic style={{ marginTop: "0" }}>
                          <div style={{ color: "#fff" }}>{title}</div>
                          <UnderLineBox>
                            <RedLine style={{ backgroundColor: "#fdd600" }} />
                            <BlackLine style={{ backgroundColor: "#fff" }} />
                          </UnderLineBox>
                          <Datecreate>
                            <FaCalendarAlt size='0.7rem' style={{color:'#ffffff'}}/>
                            <DateDetail>{`${date.day()} ${monthtxt} ${yeartxt}`}</DateDetail>
                          </Datecreate>
                        </Topic>
                      </NewsText>
                    </NewsTextContainer> */}
                  </Col>
                )
              }
              
            })
          )}
        </Row>
        <BottonTabAn onClick={OnClickBusiness}>
          <ButtonPush name={t('home.more')} />
        </BottonTabAn>
      </ContainerCustom>
    );
  };

  const MemberPart = () => {
    const { t,i18n } = useTranslation();
    return (
      <ContainerCustom>
        <SecondTopicText state={i18n.language} name={t('navbar.member')} />
        <Row md={3} style={{justifyContent:'center'}}>
          <Col xs={12}>
            <Buttondiv onClick={OnClickMember}>
              <ButtonEconomic style={{borderRadius:10}} src={yellowButton} alt="button" />
              <TextButton>{t('navbar.dropdown.member')}</TextButton>
            </Buttondiv>
          </Col>
          <Col xs={12}>
            <Buttondiv onClick={OnClickRegister}>
              <ButtonEconomic style={{borderRadius:10}} src={yellowButton} alt="button" />
              <TextButton>{t('navbar.dropdown.register')}</TextButton>
            </Buttondiv>
          </Col>
         
        </Row>
        {/* <BackgroundMember>
          <Row>
            <Col md={{ span: 5 }}>
              <div style={{ textAlign: "center" }}>
                <Circle src={pesident} alt="broadmem" />
              </div>
            </Col>
            <Col md={{ span: 6, offset: 1 }}>
              <div>
                <Head1 style={{ color: "#fff" }}>ณรงศักดิ์ พุทธมงคล</Head1>
                <Head2 style={{ color: "#fff" }}>
                  NARONGSAK PUTTHAPORNMONGKOL
                </Head2>
                <Head2 style={{ color: "#fff", fontWeight: "bold" }}>
                {t('home.president')}
                </Head2>
                <div
                  style={{
                    border: "1px solid #fff",
                    width: "33%",
                    margin: "10px 0 30px 0",
                  }}
                />
                <ButtonTabMember>
                  <div>
                    <Picdiv
                      style={{ width: "272.34px" }}
                      onClick={OnClickMember}
                    >
                      <HomeButton src={BlueButton} alt="button" />
                      <TextButton style={{fontSize:'1rem'}}>{t('navbar.dropdown.member')}</TextButton>
                    </Picdiv>
                    <Picdiv style={{ width: "272.34px" }}>
                      <HomeButton src={BlueButton} alt="button" />
                      <TextButton style={{fontSize:'1rem'}}>{t('navbar.dropdown.register')}</TextButton>
                    </Picdiv>
                  </div>
                </ButtonTabMember>
              </div>
            </Col>
          </Row>
        </BackgroundMember> */}
      </ContainerCustom>
    );
  };

  const AboutPart = () => {
    const [about, setabout] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        axios
        .get(API_IP + "/Mission_vision", CONFIG)
        .then(function (response) {
          setabout(response.data);
        })
        .catch(function (error) {
        })
      };
      fetchData();
    }, []);
    const { t,i18n } = useTranslation();
    if(about.length > 0){
      var description = ''
      if(i18n.language == 'en'){
        description =about[0].description_vision_en
      }else if(i18n.language == 'th'){
        description =about[0].description_vision_th
      }else if(i18n.language == 'ch'){
        description =about[0].description_vision_cn
      }
    }
    return (
      <ContainerCustom>
        <MainTopicText state={i18n.language} name={t('home.vision_mission')} />
        <Row>
          <Col md={{ offset: 1, span: 4 }} style={{ textAlign: "center" }}>
            <PicBox style={{objectFit:'contain'}} src={logo} alt="logo" />
          </Col>
          <Col md={{ span: 6 }}>
            <Head1>THAI-CHINESE</Head1>
            <Head2>CHAMBER OF COMMERCE</Head2>
            {about.length > 0 &&<DetailAbout style={{
              lineHeight:'1.5em',
              height: '7.5em',
              overflow:'hidden'
            }} dangerouslySetInnerHTML={{__html: description}}/>}
            <ButtonTab onClick={OnClickVisionmission} style={{ marginBottom: "30px" }}>
              <ButtonPush name={t('home.more')} />
            </ButtonTab>
          </Col>
        </Row>
      </ContainerCustom>
    );
  };

  const [banner, setbanner] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/banner", CONFIG)
      .then(function (response) {
        setbanner(response.data);
        console.log('banner path!!',response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);
  return (
    <div>
      <NavB />
      <CarouselMain data={banner} flag_banner={true}/>
      {/* {HighlightPart()} */}
      {AboutPart()}
      {InformationPart()}
      {ActivityPart()}
      {MediaPart()}
      {BusinessPart()}
      {MemberPart()}
      
      <Footer />
    </div>
  );
};

export default HomePage;
