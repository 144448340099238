import styled from "styled-components";

export const SearchTag = styled.input`
        font-family: promptLight;
        ::-webkit-input-placeholder {
            color: #918338;
        }
        color: #918338;
        border-color: #152d83 !important;
        border-width: 3px;
        border-radius: .50rem;
        height: 100%;
        width: 100%;
        border-style: solid;
        padding-left: 15px;
        font-size: 1.2rem;
        font-weight: bold;
`;

export const SelectTag = styled.select`
        font-family: promptLight;
        ::-webkit-input-placeholder {
            color: #918338;
        }
        color: #918338;
        border-color: #152d83 !important;
        border-width: 3px;
        border-radius: .50rem;
        height: 100%;
        width: 100%;
        border-style: solid;
        padding-left: 11px;
        font-size: 1.2rem;
        font-weight: bold;
        // -webkit-appearance: none;
        // background-image: url("data:image/svg+xml;utf8,<svg fill='rgb(21, 45, 131)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        // background-repeat: no-repeat;
        // background-position-x: 95%;
        // background-position-y: 4px;
        // background-size: 40px;
        
`;

export const ButtonSearch = styled.button`
        border-style: solid;
        border-color: #152d83 !important;
        border-width: 3px;
        border-radius: .50rem;
        background-color: white;
        width:99.5%;
        height:100%;
        padding:0px;
        font-size:25px;
        padding-bottom: 5px;
`;

export const ButtonA = styled.a`
        padding-top: 8px;
        font-family: promptLight;
        padding: 8px 5px 0px 5px;
        color:#ffff;
        paddingTop: '9px'
        
`;

export const BottonTabAn = styled.div`
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
`;

export const NCompany = styled.div`
        font-family: promptLight;
        font-size: 1.2rem;
        font-weight: bold;
        color: #152d83;
`;

export const PictureScope = styled.div`
        background-color: transparent;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        border: 0;
        height: 200px;
        text-align: center;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        flex-direction: column;
        padding: .75rem 1.25rem;
`;
