import styled from "styled-components";
import { Container } from "react-bootstrap";

export const LandingBackground = styled.div`
  background-image:url("../../modules/images/ArtworkForWebsitRepeat.jpg")
  width: 100%;
  display: flex;
  justify-content: center;
`
export const LandingBanner = styled.img`
  margin: 50px 0;
  width: 60%;
  height: 40%;
`

export const ButtonImage = styled.img`
  width: 100%;
  height: auto;
`