import React from "react";
import { Carousel } from "react-bootstrap";
import { CarouselCustom, Line, PicBox } from "./styled";
const CarouselMain = ({ Page, data, flag_banner }) => {
  return (
    <div>
      <CarouselCustom>
        {
          data.map((item, index)=>{
            return(
              
              <Carousel.Item key={index}>
                {
                  flag_banner == true ?
                  <a target={'_blank'} href={item.link}>
                  <PicBox src={item.url_img} alt={index} />
                  </a>
                  :
                  <a target={'_blank'} href={item.link}>
                  <PicBox src={item.img} alt={index} />
                  </a>
                }
                
              </Carousel.Item>
              
            )
          })
        }
      </CarouselCustom>
      {Page ? null : <Line />}
    </div>
  );
};

export default CarouselMain;
