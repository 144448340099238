import React, { useState, useEffect } from "react";
import MainTopicText from "modules/MainTopicText";
import { FormCustom, MapBox, ButtonTab, Butt } from "./styled";
import { Form, Container, Row, Col } from "react-bootstrap";
import ButtonPush from "modules/ButtonPush";
import map from "modules/images/map.jpg";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
const MapWithAMarker = withScriptjs(withGoogleMap(props =>
  <GoogleMap
    defaultZoom={15}
    defaultCenter={{ lat: 13.717978, lng: 100.5194251 }}
  >
    <Marker
      position={{ lat: 13.717978, lng: 100.5194251 }}
    />
  </GoogleMap>
));
const ContactPage = () => {
  const [Email, setEmail] = useState('');
  const [Name, setName] = useState('');
  const [Subject, setSubject] = useState('');
  const [Detail, setDetail] = useState('');
  const onSentEmail = () => {
    console.log('Email',Email)
    console.log('Name',Name)
    console.log('Subject',Subject)
    console.log('Detail',Detail)
    var option = {
      email:Email,
      name:Name,
      subject:Subject,
      detail:Detail
    }
    let formBody = []
    for(let property in option){
      let encodeKey = encodeURIComponent(property)
      let encodeValue = encodeURIComponent(option[property])
      formBody.push(encodeKey + "=" + encodeValue)
    }
    formBody = formBody.join('&')
    console.log('formBody',formBody)
    var config = {
      headers: {
        "content-type": "application/x-www-from-urlencoded"
      },
      timeout: 10000
    }
    axios
    .post(API_IP + "/Sendmail",formBody, config)
    .then(function (response) {
      console.log('Sendmail',response)
    })
    .catch(function (error) {
      console.log('Sendmail error',error)
    })

    // fetch(API_IP+'/Sendmail',{
    //   method: 'POST',
    //   headers: {
    //     "content-type": "application/x-www-from-urlencoded"
    //   },
    //   body: formBody
    // }).then((response)=> response.json())
    // .then((responseData)=>{
    //   console.log('Sendmail',responseData)
    // }).catch((error)=>{
    //   console.log('Sendmail error',error)
    // })
  }
  function handleChangeEmail(e) {
    setEmail(e.target.value)
  }
  function handleChangeName(e) {
    setName(e.target.value)
  }
  function handleChangeSubject(e) {
    setSubject(e.target.value)
  }
  function handleChangeDetail(e) {
    setDetail(e.target.value)
  }
  return (
    <div>
      <NavB />
      <Container>
        <MainTopicText name="CONTACT" />
        <Row className="justify-content-md-center">
          <Col md={{ span: 8 }}>
            <FormCustom>
              <Form.Control size="lg" type="text" placeholder="Email" onChange={handleChangeEmail}/>
              <br />
              <Form.Control size="lg" type="text" placeholder="Name" onChange={handleChangeName}/>
              <br />
              <Form.Control size="lg" type="text" placeholder="Subject" onChange={handleChangeSubject}/>
              <br />
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" rows="6" placeholder="Detail" onChange={handleChangeDetail}/>
              </Form.Group>
            </FormCustom>
          </Col>
        </Row>
        <Container>
          <ButtonTab>
            <Butt>
              <div onClick={()=> onSentEmail()}>
                <ButtonPush name="SEND" />
              </div>
              <ButtonPush name="MAP" />
            </Butt>
          </ButtonTab>
        </Container>
        <Row className="justify-content-md-center">
          <Col md={{ span: 12 }}>
            {/* <MapBox src={map} alt="MapBox" /> */}
            <MapWithAMarker
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyA1fjbE_674WQWaK6CpTOww3pA7PNSKIiQ&v=3.exp&libraries=geometry,drawing,places"
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `400px` }} />}
              mapElement={<div style={{ height: `100%`,marginTop:`10px`, borderRadius:'30px' }} />}
            />
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactPage;
