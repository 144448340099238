import React from "react";
import {
  Card,
  PicBoxFix,
  TopicCenterEn,
  TopicCenterTh
} from "./styled";
import { useTranslation } from "react-i18next";
const NewsChamber = ({ data, link }) => {
  const { t,i18n } = useTranslation();
  var title = ''
  var the = ''
  if(i18n.language == 'en'){
    title = data.name_en + data.lastname_en
    the = data.era_en
  }else if(i18n.language == 'th'){
    title = data.name_th + data.lastname_th
    the = data.era_th
  }else if(i18n.language == 'ch'){
    title = data.name_cn + data.lastname_cn
    the = data.era_cn
  }
  console.log('test test',data )
  return (
      <Card>
        <div style={{width:'100%',display:'flex',alignItems:'center',justifyContent:'center'}}>
        <PicBoxFix style={{alignSelf:'center',backgroundColor:'green'}} src={data.url_img} />
        </div>
        <TopicCenterEn>
          <div>{title}</div>
        </TopicCenterEn>
        <TopicCenterEn>
          <div>{the}</div>
        </TopicCenterEn>
      </Card>
  );
};

export default NewsChamber;
