import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { th } from './locales/th' 
import { en } from './locales/en' 
import { ch } from './locales/ch' 


i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      th: {
        translation: th
      },
      ch: {
        translation: ch
      }
    },
    lng: "th",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;