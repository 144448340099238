import React from "react";
import mainTopicBlue from "modules/images/mainTopicBlue.svg";
import mainTopicMobileBlue from "modules/images/mainTopicMobileBlue.svg";
import { TopicBox, TextBox2, PicBox, TextBox, Cover } from "./styled";
import MediaQuery from "react-responsive";
const MainTopicTextBlue = ({ name, state }) => {
  const renderMainTopicText = () => {
    if (state == 'th' || state == 'en') {
      return <TextBox>{name}</TextBox>;
    } else {
      return <TextBox2>{name}</TextBox2>;
    }
  };
  return (
    <TopicBox>
      <MediaQuery maxDeviceWidth={767}>
        <Cover>
          {renderMainTopicText()}
          <PicBox src={mainTopicMobileBlue} alt="mainTopicAC" />
        </Cover>
      </MediaQuery>
      <MediaQuery minDeviceWidth={768}>
        <Cover>
          {renderMainTopicText()}
          <PicBox src={mainTopicBlue} alt="mainTopicAC" />
        </Cover>
      </MediaQuery>
    </TopicBox>
  );
};

export default MainTopicTextBlue;
