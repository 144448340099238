import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import {
  Card,
  PicBoxFix,
  Topic,
  RedLine,
  BlackLine,
  UnderLineBox,
  Title,
  TextDetail,
} from "./styled";
import { useHistory, Link } from "react-router-dom";
import CarouselMain from "modules/CarouselMain";
import MainTopicText from "modules/MainTopicText";
import PaginationTab from "modules/PaginationTab";
import NewsCard from "modules/NewsCard";
import { API_IP, CONFIG } from '../../constant/constant'
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import IconPDFmember from '../../modules/images/Icon-PDF-member.png'
import IconPDFregister from '../../modules/images/Icon-PDF-register.png'
import register from '../../modules/PDF/register.pdf'
import member from '../../modules/PDF/member.pdf'
import { useTranslation } from "react-i18next";
const MemberRegister = () => {
  const { t, i18n } = useTranslation();
  const [banner, setbanner] = useState([]);
  const [filePDF, setfilePDF] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/banner", CONFIG)
      .then(function (response) {
        setbanner(response.data);
      })
      .catch(function (error) {
      })

      axios
      .get(API_IP + "/File_register", CONFIG)
      .then(function (response) {
        setfilePDF(response.data);
        console.log(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);

  var member_link = ''
  var register_link = ''
  if(filePDF.length > 0){
    if(filePDF[0].member_file_th != ''){
      member_link = filePDF[0].member_file_th;
    }
    if(filePDF[0].rigister_file_th != ''){
      register_link = filePDF[0].rigister_file_th;
    }
  }
  return (
    <div>
      <NavB />
      <CarouselMain data={banner} flag_banner={true}/>
      <Container>
        <MainTopicText state={i18n.language} name={t('navbar.dropdown.register')} />
        <Row md={6} style={{justifyContent:'center'}}>
          <Col xs={12}>
            <a style={{textDecoration:'none'}} href={member_link} target = "_blank">
              <Card style={{height:'300px'}}>
                <PicBoxFix src={IconPDFmember}/>
                <Topic style={{height:'45%'}}>
                  <Title>{t('register.member')}</Title>
                  <UnderLineBox>
                    <RedLine />
                    <BlackLine />
                  </UnderLineBox>
                  <TextDetail>{t('register.download')}</TextDetail>
                </Topic>
              </Card>
            </a>
          </Col>
          <Col xs={12}>
            <a style={{textDecoration:'none'}} href={register_link} target = "_blank">
              <Card style={{height:'300px'}}>
                <PicBoxFix src={IconPDFregister}/>
                <Topic style={{height:'45%'}}>
                  <Title>{t('register.register')}</Title>
                  <UnderLineBox>
                    <RedLine />
                    <BlackLine />
                  </UnderLineBox>
                  <TextDetail>{t('register.download')}</TextDetail>
                </Topic>
              </Card>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default MemberRegister;
