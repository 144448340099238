import React, { useState, useEffect } from "react";
import HeaderRender from "modules/HeaderRender";
import { HEADER_STATES } from "modules/HeaderRender";
import NavB from "modules/NavB";
import Footer from "modules/Footer";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_IP, CONFIG } from '../../constant/constant'
const HonorMember = () => {
  const { t } = useTranslation();
  const [honorMembers, sethonorMembers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      axios
      .get(API_IP + "/Profile/honorary", CONFIG)
      .then(function (response) {
        sethonorMembers(response.data);
      })
      .catch(function (error) {
      })
    };
    fetchData();
  }, []);

  console.log('HonorMember',honorMembers)
  return (
    <div>
      <NavB />
      <HeaderRender
        numHeader={HEADER_STATES.HonorMember}
        name={t('navbar.dropdown.hornor_member')}
        data={honorMembers}
        data2={0}
        data3={0}
      />
      <Footer />
    </div>
  );
};

export default HonorMember;